import React, { useEffect, useMemo, useState } from 'react'
import { useParams, useLocation } from "react-router-dom";

import PositionApi from 'src/services/apis/positions';
import AssessmentComposition from './AssessmentStructure';
import ListItems from './ListItems';
import SkillsSideMenu from './SkillsSideMenu';

import styles from "./styles.module.scss";
import { storage } from 'src/services/config/storage';
import { getCompanyName } from 'src/utils/helperFunctions';

function PositionDetails() {

	const location = useLocation();
	const { positionId, orgId } = useParams();
	const token = storage.get.authToken();

	const [companyName, windowUrl, isWhiteLabelled] = getCompanyName()

	const [positionData, setPositionData] = useState(null);
	const [pageLoader, setPageLoader] = useState(true);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const apiKey = queryParams.get('apiKey');

		// Replaces URL without refreshing
		window.history.replaceState(null, '', window.location.pathname);

		if (positionId && apiKey) {
			fetchPositionDetailsFromApiKey(apiKey)
		} else if ((positionId && orgId && token)) {
			fetchPositionDetails()
		} 
	}, [])

	const fetchPositionDetails = async () => {
		try {
			const response = await PositionApi.getPositionDetails({
				positionId,
				organizationId: orgId,
				organizationType: 'company'
			})
			setPositionData(response?.positionDetails)
			setPageLoader(false)
		} catch (e) {
			console.error(e)
		} finally {
			//pass
		}
	};

	const fetchPositionDetailsFromApiKey = async (apiKey) => {

		try {
			const response = await PositionApi.getPositionDetailsFromApiKey({
				positionId,
				apiKey
			})
			setPositionData(response?.positionDetails)
			setPageLoader(false)
		} catch (e) {
			console.error(e)
		} finally {
			//pass
		}
	};

	const mapAssessmentSkillsWithBuckets = () => {
		let skills = positionData?.skills ?? [];
		let obj = { "Associated Skills": [] }

		skills.forEach((skill) => {
			obj["Associated Skills"] = [...obj["Associated Skills"], { name: skill?.name }];
		})
		return obj;
	};

	const data = {
		heading: isWhiteLabelled ? '' : "The unberry assessment",
		description:
			"The most advanced tools with the most modern science measuring the following skills",
		btnText: "Know More",
		onBtnClick: () => { },
		children: (
			<ListItems
				mapAssessmentSkillsWithBuckets={mapAssessmentSkillsWithBuckets}
			/>
		),
	};

	const modulesData = useMemo(() => {
		const modules = positionData?.modules
		return modules;
	}, [positionData])

	return (
		<div className={styles.container}>
			<div
				className={`${styles.skillsSideMenu} ${styles.readOnlyMaxHeight}`}>
				<SkillsSideMenu
					data={data}
					showButton={false}
				/>
			</div>
			<AssessmentComposition
				readOnly={true}
				readOnlyData={modulesData}
			/>
		</div>
	)
}

export default PositionDetails