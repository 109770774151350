import React, { useState, useMemo, useCallback } from "react";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import Listing from "src/components/Common/Listing";
import CodeEditorView from "../CodeEditorView";
import {
  QUESTIONS,
  QUIZ_DISPLAY_SECTIONS,
  TEST_CASE_HEADER_PLACEHOLDER,
  TEST_CASE_STATUS,
  TEST_CASE_TABLE_HEADER,
} from "../../constant";
import ChatAnswer from "./ChatAnswer";
import { AudioPlayer } from "../AudioPlayer";
import { capitalizeTitle, getCompanyName, roundOff } from "src/utils/helperFunctions";
import { DEFAULT_COMPANY_NAME } from "src/constants/globalConstants";
import ScorePills from "../ScorePills";
import { GetMediaComponent } from "../utils";

const ProgrammingSection = (props) => {
  const { data } = props;
  const [displayTestCases, setDisplayTestCases] = useState(false);

  const [orgNameFromUrl, windowUrl] = getCompanyName();
  const isWhiteLabelledOrg = orgNameFromUrl !== DEFAULT_COMPANY_NAME;

  // Hide remarks if whitelabelled orgName -> Check from url
  const remarks = !isWhiteLabelledOrg && data?.score?.remark ? (
    <div className="question-summary">
      <div className="flex items-center justify-between">
        <h2>REMARKS</h2>
      </div>
      <p>{data?.score?.remark}</p>
    </div>
  ) : <></>

  // Extract time, memory and test-cases results for a coding submission
  const getCodeResults = (data) => {
    const {
      submission: { testcasesPassed, testcasesTotal, testcaseResults, rawData },
    } = data;
    const testSubmissions = rawData && rawData.submissions;
    let programHeader = TEST_CASE_HEADER_PLACEHOLDER,
      sourceCode = null;
    const testCasesResults = [];

    if (testSubmissions?.length) {
      let totalAverageTime = 0,
        totalAverageMemory = 0,
        language = null,
        testCasesCount = null;

      testSubmissions.forEach((sub, index) => {
        totalAverageTime += parseFloat(sub.time);
        totalAverageMemory += sub?.memory;
        const checkTestcaseFailStatus =
          testcaseResults[index].status === TEST_CASE_STATUS.FAIL;
        const testCaseResult = {
          input: `Input #${index + 1}`,
          result: checkTestcaseFailStatus ? (
            <CloseCircleOutlined />
          ) : (
            <CheckCircleOutlined />
          ),
          time: sub.time,
          memory: sub.memory,
          score: checkTestcaseFailStatus ? 0 : testcaseResults[index].points,
        };
        testCasesResults.push(testCaseResult);
      });

      totalAverageTime = `${(totalAverageTime / testSubmissions.length).toFixed(
        5
      )} sec`;
      totalAverageMemory = `${Math.round(
        totalAverageMemory / testSubmissions?.length
      )} KB`;
      language = `${testSubmissions[0].language.name}`;
      testCasesCount = `${testcasesPassed}/${testcasesTotal} Correct`;
      const headerData = [
        totalAverageTime,
        totalAverageMemory,
        language,
        testCasesCount,
      ];

      programHeader = programHeader.map((elem, index) => ({
        ...elem,
        value: headerData[index],
      }));
      sourceCode = testSubmissions[0].source_code;
    }
    return [programHeader, testCasesResults, sourceCode];
  };

  const [programHeader, testCasesResults, sourceCode] = useMemo(
    () => getCodeResults(data),
    [data]
  );

  // To be passed to ScorePills
  const customTextObject = data?.answer?.customText;
  const answerScore = Array.isArray(data?.testcases) ? data?.score : (data?.answer?.score || 0);

  return (
    <div className="program flex flex-col">
      <ScorePills
        detailedScoreObject={customTextObject}
        score={answerScore}
        contextAnalysis={customTextObject?.contextAnalysis ?? {}}
      />
      <div
        className={`flex items-center justify-between mt-10 program-header ${displayTestCases ? "w80" : "w90"
          }`}
      >
        {programHeader.map((elem, index) => (
          <div key={elem.heading} className="flex items-end">
            {index !== 0 && <div className="vertical-spacer big mr-10"></div>}
            <div className="flex flex-col">
              <p className="program-header-heading">
                {elem.heading}
                <span
                  className={`program-content-toggle ml-12 ${displayTestCases ? "hidden" : ""
                    }`}
                  onClick={() => setDisplayTestCases((prev) => !prev)}
                >
                  {programHeader.length - 1 === index
                    ? "See All Test Cases"
                    : ""}
                </span>
              </p>
              <p className="program-header-value mt-6">{elem.value}</p>
            </div>
          </div>
        ))}
      </div>
      <div
        className={`program-content ${displayTestCases ? "mt-30" : "hidden"}`}
      >
        <Listing
          dataSource={testCasesResults}
          headerLayout={"repeat(4, 1.5fr) 0.3fr"}
          headerClassName={"test-cases-header"}
          dataLayout={"repeat(4, 1.5fr) 0.3fr"}
          headers={TEST_CASE_TABLE_HEADER}
          dataKeys={[
            {
              name: "input",
              render: (data) => {
                return (
                  // Add key here `${id}-input` when integrating Programming API
                  <div className="input">{data.input}</div>
                );
              },
            },
            {
              name: "result",
              render: (data) => {
                return (
                  // Add key here `${id}-result` when integrating Programming API
                  <div className="result">{data.result}</div>
                );
              },
            },
            {
              name: "time",
              render: (data) => {
                return (
                  // Add key here `${id}-time` when integrating Programming API
                  <div className="time">{data.time}</div>
                );
              },
            },
            {
              name: "memory",
              render: (data) => {
                return (
                  // Add key here `${id}-memory` when integrating Programming API
                  <div className="memory">{data.memory}</div>
                );
              },
            },
            {
              name: "score",
              render: (data) => {
                return (
                  // Add key here `${id}-score` when integrating Programming API
                  <div className="score">{data.score}</div>
                );
              },
            },
          ]}
        />
        <p className="editor-header mt-50 mb-20">
          <span>{`</>`}</span> Source Code
        </p>
        <CodeEditorView code={sourceCode} />
      </div>
      {remarks}
    </div>
  );
};

const McqSection = (props) => {
  // Blank Indices keeping track of sentence completion blanks.
  const {
    data,
  } = props;
  const { answer, options, question } = data;
  const checkOptionLengthWrapper = (optionLength) =>
    options.some((option) => option?.text?.length > optionLength);
  const convertOptionListingToChar = (index) =>
    `${String.fromCharCode(index + 1 + 64).toUpperCase()}.`;
  const checkOptionLength = options?.length > 4 || checkOptionLengthWrapper(30);

  const extractMediaDataForOption = useCallback((option) => {
    const { type, medium } = option ?? {};
    const medialink = medium?.link;

    const audioSrc = type === "audio" ? medialink : null;
    const videoSrc = type === "video" ? medialink : null;
    const imageSrc = type === "image" ? medialink : null;

    return [audioSrc, videoSrc, imageSrc, medialink]
  }, [answer]);

  const isSpeechQuestion = question.type.includes(QUESTIONS.SPEECH);
  const isSentenceCompletion = question.type.includes(
    QUESTIONS.SENTENCE_COMPLETION
  );
  const isTypingText = question.type.includes(QUESTIONS.TYPING_TEXT);
  const isFreeText = question.type.includes(QUESTIONS.FREE_TEXT);
  const isChatQuestion = question.type.includes(QUESTIONS.CHAT);

  // remove relevancy, summary and remarks because those are being shown already
  const contextAnalysisKeys = Object.keys(answer?.customText?.contextAnalysis ?? {})
    ?.filter(key => key !== "summary" && key !== "relevancy" && key !== "remarks")

  // To be passed to ScorePills
  const customTextObject = answer?.customText;
  const answerScore = isChatQuestion ? customTextObject : Array.isArray(data?.testcases) ? data?.score : (data?.answer?.score || 0);

  /**
   * Checks the weights of options in an array and returns the highest and lowest weights.
   * 
   * Required to assign -> correct, semi-correct and wrong color tags
   * Logic implemented in option mapping -> Component return()
   */
  const weightedQuestionCheck = (options) => {
    if (options.length === 0) return [0, 0];
    let highestWeight = 0, lowestWeight = 0;

    for (const option of options) {
      const weight = option.weight;
      if (weight > highestWeight) highestWeight = weight;
      if (weight < lowestWeight) lowestWeight = weight;
    }
    return [highestWeight, lowestWeight];
  };

  const [
    highestOptionWeight,
    lowestOptionWeight
  ] = weightedQuestionCheck(options);

  /**
   * Using two pointer approach to find word/multiple words 
   * Which replace [blank] in questionText
   * 
   * @param {object} question 
   * @param {object} answer 
   * @returns senetenceCompletionText -> Replacing [blank] positions with underlined word/s
   */
  const getSentenceCompletionAnswerText = (question, answer) => {
    const questionText = question.question;
    const answerText = answer.answerText;
    if (!answerText) return <></>;

    // First, getting all the non-blank text
    // Second, replacing non-blank text with "---------" (random string) to split and get the blanks value
    // Then just looping through non-blank array and appending the blanksValue array if value exists
    // * Changed this to handle cases when [blank] is with period or special character. (It also saves nested looping)
    const questionWithoutBlank = questionText.split("[blank]");

    let blanksValue = answerText;
    questionWithoutBlank.forEach((text) => {
      blanksValue = blanksValue.replace(text, "---------");
    });

    blanksValue = blanksValue.split("---------").filter((value) => !!value);

    return questionWithoutBlank.map((text, blankIndex) => (
      <>
        <span className="px-4 weight-600">{text}</span>
        {blanksValue[blankIndex] ? (
          <span className="highlight-word mx-4 py-2 px-10">
            {blanksValue[blankIndex]}
          </span>
        ) : null}
      </>
    ));
  };

  const summary = (answer?.score?.summary || isChatQuestion) && (
    <div className="question-summary">
      <div className="flex items-center justify-between">
        <h2>SUMMARY</h2>
        <div className="relevancy flex items-center justify-start">
          <span className="mr-4">Relevancy:</span>
          <span className="score context-value mr-6">
            {
              isChatQuestion ?
                answer.customText?.relevancy ?? "--"
                : answer.score?.relevancy ?? "--"
            }
          </span>
          {
            contextAnalysisKeys?.map((contextKey, index) => <>
              <div className="flex items-center justify-start">
                <span className="context-key">{capitalizeTitle(contextKey)}:</span>
                <span className="context-value ml-4">
                  {
                    contextKey === "CEFR Grade"
                      ? answer?.customText?.contextAnalysis[contextKey]
                      : roundOff(answer?.customText?.contextAnalysis[contextKey])
                  }
                </span>
              </div>
              <span className={`contextkey-seperator ml-2 mr-2 ${(index === (contextAnalysisKeys.length - 1)) ? "hide" : ""}`} />
            </>)
          }
        </div>
      </div>
      {/* Both summary or remark key possible for chat questions */}
      <p>{isChatQuestion ? (answer.customText?.remark || answer.customText?.summary) : answer.score?.summary}</p>
    </div>
  );

  const remarks = answer?.score?.remarks ? (
    <div className="question-summary">
      <div className="flex justify-between">
        <h2>REMARKS</h2>
        <div className="relevancy remarks flex items-center justify-end">
          {/* show context analysis here in remarks only if summary is not present */}
          {
            !answer?.score?.summary ?
              contextAnalysisKeys?.map((contextKey, index) => <>
                <div className="flex items-center justify-start">
                  <span className="context-key">{contextKey}:</span>
                  <span className="context-value ml-4">
                    {
                      contextKey === "CEFR Grade"
                        ? answer?.customText?.contextAnalysis[contextKey]
                        : roundOff(answer?.customText?.contextAnalysis[contextKey])
                    }
                  </span>
                </div>
                <span className={`contextkey-seperator ml-2 mr-2 ${(index === (contextAnalysisKeys.length - 1)) ? "hide" : ""}`} />
              </>)
              : <></>
          }
        </div>
      </div>
      <p>{answer.score.remarks}</p>
    </div>
  ) : <></>;

  const nonAttemptedMsg = (
    <p className="mt-10 text-14 semibold text-red">
      Candidate couldn't attempt this question.
    </p>
  );

  // Check and show free text as html if received as so
  const isFreeTextAnswerHtml = isFreeText && /<\/?[a-z][\s\S]*>/i.test(answer?.answerText);

  return isFreeText || isTypingText ? (
    <div className="mt-20">
      <p className="mb-20">{`Answer`}</p>
      {
        isFreeTextAnswerHtml ?
          <div
            className="answer-content-html"
            dangerouslySetInnerHTML={{
              __html: answer?.answerText
            }}
          /> :
          <pre className="wrap-whitespace">{answer?.answerText}</pre>
      }
      <ScorePills
        detailedScoreObject={customTextObject}
        score={answerScore}
        contextAnalysis={customTextObject?.contextAnalysis ?? {}}
      />
      {summary}
      {remarks}
      {!answer?.answerText && nonAttemptedMsg}
    </div>
  ) : isSpeechQuestion ? (
    <div className="mt-20">
      <div className="flex items-center">
        <span className="semibold mr-20">Answer</span>
        <AudioPlayer src={answer?.media?.link} />
      </div>
      <ScorePills
        detailedScoreObject={customTextObject}
        score={answerScore}
        contextAnalysis={customTextObject?.contextAnalysis ?? {}}
      />
      {summary}
      {remarks}
      {!answer?.media?.link && nonAttemptedMsg}
    </div>
  ) : isChatQuestion ? (
    <div className="mt-20">
      <ChatAnswer answer={answer} />
      <ScorePills
        detailedScoreObject={customTextObject}
        score={answerScore}
        contextAnalysis={customTextObject?.contextAnalysis ?? {}}
      />
      {summary}
      {remarks}
      {/* Awaiting design for answerText */}
      {/* {!answer?.answerText && nonAttemptedMsg} */}
    </div>
  ) : isSentenceCompletion ? (
    <div className="mt-20">
      <p className="mb-20">{`Answer`}</p>
      <p className="line-break-any">
        {getSentenceCompletionAnswerText(question, answer)}
      </p>
      <ScorePills
        detailedScoreObject={customTextObject}
        score={answerScore}
        contextAnalysis={customTextObject?.contextAnalysis ?? {}}
      />
      {summary}
      {remarks}
      {!answer?.answerText && nonAttemptedMsg}
    </div>
  ) : (
    <div className="options flex flex-col">
      {options && options.length > 0 && (
        <div>
          <div className="options-header mt-12 mb-6">Options</div>
          <div
            className={`
            ${checkOptionLength
                ? "flex-col justify-between"
                : "flex justify-start flex-wrap"
              } 
              options-map
            `}
          >
            {options.sort((a, b) => a?.order - b?.order).map((option, index) => {
              const optionText = option?.text;
              const [audioSrc, vidSrc, imgSrc, hasMediaSource] = extractMediaDataForOption(option)
              const showMarkedBox = answer?.optionId === option?.id;
              const unWeightedOption = option?.weight === null || option?.weight === undefined;
              let markedBoxCorrect = showMarkedBox && "correct";

              // For weightedQuestion, assign markedBoxCorrect value -> Doubly used in css (semi-correct correspons to yellow tag)
              if (!unWeightedOption && markedBoxCorrect) {
                if (option?.weight === highestOptionWeight) markedBoxCorrect = "correct";
                else if (option?.weight === lowestOptionWeight && lowestOptionWeight <= 0) markedBoxCorrect = "wrong";
                else markedBoxCorrect = "semi-correct";
              } else {
                if (showMarkedBox && !option.correct) markedBoxCorrect = false;
              }

              const selectedOptionUI = showMarkedBox && (
                <span
                  className={`options-marked 
                        ${!markedBoxCorrect ? "wrong" : markedBoxCorrect} ml-10`}
                >
                  {markedBoxCorrect
                    ? `${unWeightedOption ? "Correctly Answered" : "Selected Answer"}`
                    : "Incorrectly Answered"}
                </span>
              )

              return (
                <div
                  className={`options-content flex items-center 
                    ${options.length - 1 > index && !checkOptionLength
                      ? "mr-100"
                      : ""
                    }
                    ${checkOptionLength ? "mt-10" : ""}
                  `}
                >
                  <span className="mr-10 option-char">
                    {convertOptionListingToChar(index)}
                  </span>
                  {
                    hasMediaSource ? (
                      <div className="flex items-center gap-2">
                        <GetMediaComponent
                          vidSrc={vidSrc}
                          audioSrc={audioSrc}
                          imgSrc={imgSrc}
                        />
                        <span>
                          {selectedOptionUI}
                        </span>
                      </div>
                    ) : (
                      <pre
                        className={`options-text wrap-whitespace ${checkOptionLength ? "ws" : ""
                          }`}
                      >
                        {optionText}
                        {selectedOptionUI}
                      </pre>
                    )
                  }
                </div>
              );
            })}
          </div>
          {!answer?.optionId && nonAttemptedMsg}
        </div>
      )}
      <ScorePills
        detailedScoreObject={customTextObject}
        score={answerScore}
        contextAnalysis={customTextObject?.contextAnalysis ?? {}}
      />
      {remarks}
    </div>
  );
};

export const SectionContentAnswer = (props) => {
  const { activeSection, answerData } = props;

  return (
    <section className="section-content-answer">
      {activeSection === QUIZ_DISPLAY_SECTIONS.MCQ ? (
        <McqSection data={answerData} />
      ) : activeSection === QUIZ_DISPLAY_SECTIONS.PROGRAMMING ? (
        <ProgrammingSection data={answerData} />
      ) : null}
    </section>
  );
};
