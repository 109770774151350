import { useEffect, useState } from "react";
import { Clock, EyeIcon, MCQIcon, TagIcon } from "../../../assets/icons";
import styles from "./styles.module.scss";
import AssessmentCoding from "../../../assets/img/coding-quiz.png";
import BotBerry from "../../../assets/img/botberry.png";
import AudioQuiz from "../../../assets/img/audio-quiz.png";
import WrittenQuiz from "../../../assets/img/written-quiz.png";
import ClearBerry from "../../../assets/img/clearberry.png";
import QuizDefaultImage from "../../../assets/img/ImageMCQ.png";
import CodeDefaultImage from "../../../assets/img/ImageCode.jpg";


const MODULE_IMAGES = {
    botberry: BotBerry,
    coding: AssessmentCoding,
    "audio-quiz": AudioQuiz,
    "written-quiz": WrittenQuiz,
    clearberry: ClearBerry,
    quiz: QuizDefaultImage,
    mcq: QuizDefaultImage,
    code: CodeDefaultImage,
    "code-config": CodeDefaultImage,
};

const MODULE_TITLE = {
    botberry: "Game",
    code: "Coding Simulation",
    quiz: "Quiz",
    "audio-quiz": "Quiz",
    "written-quiz": "Quiz",
    clearberry: "Game",
    game: "Game",
    mcq: "MCQ",
    "code-config": "Randomised Coding Simulation",
};

const ModuleCard = ({
    traits = [],
    moduleTitle = "",
    moduleDesc = "",
    moduleType = "",
    time,
    moduleLevels = "",
    quesCount,
    previewLink,
    mediaUrl,
    id,
    showModuleDeleteAction,
    handleOnOpenDeleteModal,
    unberryPositionId,
}) => {
    const [height, setHeight] = useState("200px");

    const openModulePreview = async () => window.open(previewLink, "_blank");

    useEffect(() => {
        const divHeight = `${document.getElementById(id)?.clientHeight}px`;
        setHeight(divHeight);

        window.addEventListener("resize", () => {
            const divHeight = `${document.getElementById(id)?.clientHeight}px`;
            setHeight(divHeight);
        });
        return () => window.removeEventListener("resize", () => {
            const divHeight = `${document.getElementById(id)?.clientHeight}px`;
            setHeight(divHeight);
        });
    }, [id]);



    return (
        <article id={id} className={styles.card}>
            <div className={styles.imageWrapper} style={{ height }}>
                {mediaUrl ? (
                    <img
                        className={styles.image}
                        src={mediaUrl}
                        loader={() => mediaUrl}
                        alt="module"
                    />
                ) : (
                    <img
                        className={styles.image}
                        src={MODULE_IMAGES[moduleType]}
                        alt="module"
                    />
                )}
            </div>
            <div className={styles.content}>
                <p className={styles.title}>
                    {`${moduleType === "game" ? "Game Round - " : ""}${moduleTitle}`}
                </p>
                <div className={styles.traits}>
                    {traits.map((trait) => (
                        <div key={trait?.name} className={styles.trait}>
                            {trait?.name}
                        </div>
                    ))}
                </div>
                <div className={styles.footer}>
                    <div className={styles.details}>
                        <div className={styles.tag}>
                            <MCQIcon />
                            <span>{MODULE_TITLE[moduleType]}</span>
                        </div>
                        <div className={styles.time}>
                            <Clock />
                            <span>
                                {moduleType === "code" && !time
                                    ? "25 MINS"
                                    : `${((time || 1) / 60).toFixed(0)} ${time === 1 ? "MIN" : "MINS"
                                    }`}
                            </span>
                        </div>
                        {moduleLevels || quesCount ? (
                            <div className={styles.levels}>
                                <TagIcon />
                                <span>
                                    {moduleLevels
                                        ? `${moduleLevels} Level/s`
                                        : `${quesCount} Question/s`}
                                </span>
                            </div>
                        ) : null}
                    </div>
                    <div className={styles.actions}>
                        <button
                            className={styles.iconButton}
                            onClick={openModulePreview}>
                            <EyeIcon />
                        </button>
                    </div>
                </div>
            </div>
        </article>
    );
};

export default ModuleCard;
