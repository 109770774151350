import { useEffect, useMemo, useState } from "react"
import { ReloadOutlined } from "@ant-design/icons"
import queryString from "query-string"
import { Drawer, Select, Spin, Tabs } from "antd"
import { useParams } from "react-router-dom"
import { CloseIcon } from "src/assets/icons"
import PositionApi from "src/services/apis/positions"
import { defaultValues } from "./constants"
import { roundOff } from "src/utils/helperFunctions"
import { roundOff as floorRoundOff } from "./Stats"

const DetailedScoreDistribution = ({
    analyticsData,
    isQuestionsAnalysisVisible,
    zones,
    zoneForQuestionAnalysis,
    siteForQuestionAnalysis,
    rollTypeForQuestionAnalysis,
    levelForQuestionAnalysis,
    skillForQuestionAnalysis,
    handleCloseQuestionsAnalysis,
    handleFilterChange,
}) => {
    const [currentTab, setCurrentTab] = useState(defaultValues.TABS.TRAITS)
    const [traitAnalytics, setTraitAnalytics] = useState(null)
    const [questionAnalytics, setQuestionAnalytics] = useState(null)
    const [loading, setLoading] = useState(false)
    const [skillOptions, setSkillOptions] = useState([])
    const { positionId } = useParams()

    useEffect(() => {
        if (isQuestionsAnalysisVisible) {
            getFilteredAnalytics()
        }
    }, [zoneForQuestionAnalysis, siteForQuestionAnalysis, rollTypeForQuestionAnalysis, levelForQuestionAnalysis, isQuestionsAnalysisVisible])

    const getFilteredAnalytics = async () => {
        const filtersObj = {}
        if (!zoneForQuestionAnalysis.includes(defaultValues.FILTERS.ZONE)) {
            filtersObj.zoneName = zoneForQuestionAnalysis
        }
        if (!siteForQuestionAnalysis.includes(defaultValues.FILTERS.SITE)) {
            filtersObj.site = queryString.stringify({ name: siteForQuestionAnalysis }).split("name=")[1]
        }
        if (!rollTypeForQuestionAnalysis.includes(defaultValues.FILTERS.ROLL.ALL.value)) {
            filtersObj.roll = rollTypeForQuestionAnalysis
        }
        if (!levelForQuestionAnalysis.includes(defaultValues.FILTERS.LEVEL.ALL.value)) {
            filtersObj.level = levelForQuestionAnalysis
        }
        try {
            setLoading(true)
            setSkillOptions([])
            await Promise.all([getTraitAnalysis(filtersObj), getQuestionAnalysis(filtersObj)])
        } catch (err) { } finally {
            setLoading(false)
        }
    }

    const getTraitAnalysis = async (filters = {}) => {
        const query = Object.keys(filters)?.length > 0 ? queryString.stringify(filters) : ""
        try {
            const response = await PositionApi.getPositionTraitAnalytics(positionId, query)
            if (response.success) {
                let traitAnalyticsData = []
                let uniqueSkills = new Set()
                for (const traitId in response.data) {
                    const { displayName = "", highest = 0, percent25 = 0, median = 0, percent75 = 0, lowest = 0, maxScore = 0, } = response.data[traitId]
                    traitAnalyticsData = [...traitAnalyticsData, { id: traitId, displayName, highest, percent25, median, percent75, lowest, maxScore }]
                    uniqueSkills.add(displayName)
                }
                setTraitAnalytics(traitAnalyticsData)
                setSkillOptions(skills => {
                    const updatedSkills = new Set([...skills, ...Array.from(uniqueSkills)])
                    return Array.from(updatedSkills)
                })
            }
        } catch (err) {
            console.error(err)
        }
    }

    const getQuestionAnalysis = async (filters = null) => {
        const query = !!filters ? queryString.stringify(filters) : ""
        try {
            const response = await PositionApi.getPositionQuestionAnalytics(positionId, query)
            if (response.success) {
                let questionAnalyticsData = []
                let uniqueSkills = new Set()
                for (const questionId in response.data) {
                    const { question = "", skills = [], level = "", correctPercent = 0, incorrectPercent = 0 } = response.data[questionId]
                    questionAnalyticsData = [...questionAnalyticsData, { id: questionId, question, skills, level, correctPercent, incorrectPercent }]
                    skills.forEach(skill => {
                        uniqueSkills.add(skill)
                    })
                }
                setQuestionAnalytics(questionAnalyticsData)
                setSkillOptions(skills => {
                    const updatedSkills = new Set([...skills, ...Array.from(uniqueSkills)])
                    return Array.from(updatedSkills)
                })
            }
        } catch (err) {
            console.error(err)
        }
    }

    const handleClose = () => {
        handleCloseQuestionsAnalysis()
        setCurrentTab(defaultValues.TABS.TRAITS)
        setTraitAnalytics(null)
        setQuestionAnalytics(null)
    }

    const isHtml = (text) => (/<\/?[a-z][\s\S]*>/i.test(text))

    const filterArr = useMemo(() => ([
        {
            label: "zone",
            value: zoneForQuestionAnalysis,
            className: "mr-12",
            style: { width: 120 },
            onChange: (val) => handleFilterChange("zone", val),
            options: [
                {
                    value: defaultValues.FILTERS.ZONE,
                    label: "All",
                },
                ...zones.map(zone => ({ value: zone, label: zone }))
            ]
        },
        {
            label: "site",
            value: siteForQuestionAnalysis,
            className: "mr-12",
            style: { width: 120 },
            onChange: (val) => handleFilterChange("site", val),
            options: [{
                value: defaultValues.FILTERS.SITE,
                label: "All"
            },
            ...Object.keys(analyticsData?.zonal?.[zoneForQuestionAnalysis]?.sites ?? {})
                ?.map(site => ({ value: site, label: site }))
            ]
        },
        {
            label: "roll",
            value: rollTypeForQuestionAnalysis,
            className: "mr-12",
            style: { width: 120 },
            onChange: (val) => handleFilterChange("roll", val),
            options: Object.keys(defaultValues.FILTERS.ROLL).map(key => ({ value: defaultValues.FILTERS.ROLL[key].value, label: defaultValues.FILTERS.ROLL[key].label }))
        },
        {
            label: "level",
            value: levelForQuestionAnalysis,
            className: "mr-12",
            style: { width: 120 },
            onChange: (val) => handleFilterChange("level", val),
            options: Object.keys(defaultValues.FILTERS.LEVEL).map(key => ({ value: defaultValues.FILTERS.LEVEL[key].value, label: defaultValues.FILTERS.LEVEL[key].label }))
        },
        {
            label: "skill",
            value: skillForQuestionAnalysis,
            className: "",
            style: { width: 120 },
            onChange: (val) => handleFilterChange("skill", val),
            options: [{ label: "All", value: "all" }, ...skillOptions.map(skill => ({ label: skill, value: skill }))]
        },
    ]), [currentTab, levelForQuestionAnalysis, zoneForQuestionAnalysis, siteForQuestionAnalysis, rollTypeForQuestionAnalysis, skillForQuestionAnalysis, skillOptions])

    return (
        <Drawer
            placement="right"
            onClose={handleClose}
            visible={isQuestionsAnalysisVisible}
            destroyOnClose
            className="questions-drawer"
            closable={false}
            width={"987px"}
        >
            <div className="flex justify-between items-center pt-20 px-30">
                <div className="flex items-center justify-start">
                    {
                        filterArr?.map((filter, index) => <div className="flex flex-col items-start">
                            <p className="capitalize text-16 weight-500 color-black mb-8 ml-4">{filter.label}</p>
                            <Select
                                key={index}
                                value={filter.value}
                                className={filter.className}
                                style={filter.style}
                                onChange={filter.onChange}
                                options={filter.options}
                            /></div>)
                    }
                    <div className="flex flex-col items-start ml-12">
                        <p className="capitalize text-16 weight-500 color-black mb-8 ml-4">reset</p>
                        <div
                            role="button"
                            title="Reset filters"
                            className="reset-filters pl-12 py-12 px-20 b-12 flex items-center justify-center"
                            onClick={handleFilterChange}
                        >
                            <ReloadOutlined />
                        </div>
                    </div>
                </div>
                <CloseIcon className="cursor-pointer " onClick={handleClose} />
            </div>
            <div className="flex flex-col items-start justify-start px-30 pt-30 pb-30">
                <Tabs activeKey={currentTab} onChange={setCurrentTab}>
                    <Tabs.TabPane tab={"Competencies"} key={defaultValues.TABS.TRAITS}>
                        {

                            loading
                                ? <div className="loading-container flex justify-center pt-20"><Spin /></div>
                                : traitAnalytics?.filter(trait => trait.maxScore != 0)?.filter(trait => skillForQuestionAnalysis === "all" ? true : trait.displayName === skillForQuestionAnalysis)?.length > 0
                                    ? <table className="traits-table">
                                        <tr>
                                            {
                                                defaultValues.TABLE.TRAITS.map(header => <th
                                                    key={header}
                                                    className="text-12 weight-400 uppercase opacity-50"
                                                >
                                                    {header}
                                                </th>)
                                            }
                                        </tr>
                                        {
                                            traitAnalytics?.filter(trait => trait.maxScore != 0)?.filter(trait => skillForQuestionAnalysis === "all" ? true : trait.displayName === skillForQuestionAnalysis)?.map(trait => <tr key={trait.id}>
                                                <td><p className="trait trait-name text-14 weight-500">{trait.displayName}</p></td>
                                                <td><p className="trait text-14 weight-500">{floorRoundOff((trait.highest / trait.maxScore) * 100, 2)}%</p></td>
                                                <td><p className="trait text-14 weight-500">{floorRoundOff(trait.percent25)}/{trait.maxScore}</p></td>
                                                <td><p className="trait text-14 weight-500">{floorRoundOff((trait.median / trait.maxScore) * 100, 2)}%</p></td>
                                                <td><p className="trait text-14 weight-500">{floorRoundOff(trait.percent75)}/{trait.maxScore}</p></td>
                                                <td><p className="trait text-14 weight-500">{floorRoundOff((trait.lowest / trait.maxScore) * 100, 2)}%</p></td>
                                            </tr>)
                                        }
                                    </table>
                                    : <p className="text-18 weight-500 letter-spacing-3 mt-10 loading-container text-center">No result found</p>
                        }
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Questions" key={defaultValues.TABS.QUESTIONS}>
                        {
                            loading ? <div className="loading-container flex justify-center pt-20"><Spin /></div>
                                : questionAnalytics?.filter(question => skillForQuestionAnalysis === "all" ? true : question.skills?.includes(skillForQuestionAnalysis))?.length > 0
                                    ? <table className="questions-table">
                                        <tr>
                                            {
                                                defaultValues.TABLE.QUESTIONS.map(header => <th
                                                    key={header}
                                                    className="text-12 weight-400 uppercase opacity-50"
                                                >
                                                    {header}
                                                </th>)
                                            }
                                        </tr>
                                        {
                                            questionAnalytics?.filter(question => skillForQuestionAnalysis === "all" ? true : question.skills?.includes(skillForQuestionAnalysis))?.map(question => <tr key={question.id}>
                                                <td>
                                                    {
                                                        isHtml(question.question)
                                                            ? <div
                                                                className="ques ques-name text-14 weight-500"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: question.question
                                                                }}
                                                            />
                                                            : <p className="ques ques-name text-14 weight-500">{question.question}</p>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        question.skills?.map(skill => <p key={skill} className="ques ques-skill text-14 weight-500 my-4">{skill}</p>)
                                                    }
                                                </td>
                                                <td><p className={`ques ques-level ques-level-${question.level} text-14 weight-500 capitalize`}>{defaultValues.FILTERS.LEVEL[question.level.toUpperCase()].label}</p></td>
                                                <td><p className="ques text-14 weight-500">{roundOff(question.correctPercent)}%</p></td>
                                                <td><p className="ques text-14 weight-500">{roundOff(question.incorrectPercent)}%</p></td>
                                            </tr>)
                                        }
                                    </table>
                                    : <p className="text-18 weight-500 letter-spacing-3 mt-10 loading-container text-center">No result found</p>
                        }
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </Drawer>
    )
}

export default DetailedScoreDistribution