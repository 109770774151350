import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./PositionDetail.scss";
import TitleWithIcon from "src/components/Common/TitleWithIcon/TitleWithIcon";
import SearchInput from "src/components/Common/SearchInput";
import Pagination from "src/components/Common/PrimaryPagination";
import PageHelmet from "src/components/Common/PageHelmet";
import PositionApi from "src/services/apis/positions";
import InviteUser from "src/forms/InviteUserForm";
import { useDispatch, useSelector } from "react-redux";
import { getPositionStats, getUsers, removeInvitedUser, resetPositionStats } from "src/store/Positions/asyncActions";
import { updateUserStatus } from "src/store/Positions/reducer";
import Filters from "src/components/Common/Filters";
import UsersListingShimmer from "src/components/Common/Skeletons/UsersListingShimmer/UsersListingShimmer";
import Listing from "src/components/Common/Listing";
import CandidateFormData from "../CandidateFormData";
import { storage } from "src/services/config/storage";
import { LOGIN_TYPE, MIXPANEL_EVENTS, ORGANIZATION_TYPES, } from "src/constants/globalConstants";
import CandidateApi from "src/services/apis/candidate";
import { DATA_KEYS, HEADER_LAYOUT, POSITION_ARCHIVE_STATUS } from "src/pages/Positions/constants";
import { Mixpanel } from "src/services/mixpanel";
import { useUrlFilter } from "src/utils/hooks";
import BulkUploader from "src/forms/BulkUploader";
import OverallStats from "src/pages/MyDna/OverallStats";
import { AddIcon, GoBack } from "src/assets/icons";
import SettingsApi from "src/services/apis/settings";
import Toast from "src/components/Common/Toast";

export default function PositionDetail() {
  /**
   * ===================
   * props from parent
   * ===================
   */

  /**
   * ===================
   * props from redux
   * ===================
   */
  const users = useSelector((state) => state.positions.users);
  const positionStats = useSelector((state) => state.positions.positionStats);
  const orgId = storage.get.organizationInfo().organizationId;
  const orgType = ORGANIZATION_TYPES.COMPANY;
  /**
   *
   * ===================
   * constant variables
   * ===================
   */
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { positionId } = useParams();
  /**
   * ===================
   * useStates
   * ===================
   */
  const [filters, setFilters] = useUrlFilter();
  const [constraints, setConstraints] = useState({});
  const [config, setConfig] = useState({});
  const [positionLoginType, setPositionLoginType] = useState("")
  const [inviteUserError, setInviteUserError] = useState(null);
  const [editInviteData, setEditInviteData] = useState({})
  const [activeApiKey, setActiveApiKey] = useState('');
  const [candidateFormDataLink, setCandidateFormDataLink] = useState("")

  const initialDataLoadRef = useRef();

  const thirdPartyLogin = positionLoginType === LOGIN_TYPE.THIRD_PARTY;
  /**
   * ===================
   * useRefs
   * ===================
   */

  /**
   * ===================
   * computed variables
   * ===================
   */
  const hasFilters = filters ? Object.keys(filters).length : false;

  /**
   * ===================
   * custom hooks
   * ===================
   */

  /**
   * ===================
   * functions
   * ===================
   */

  const getPositionContraints = async () => {
    const constraints = await PositionApi.getContraints(positionId);
    if (constraints?.success) {
      setConstraints(constraints.result?.band?.data);
    }
  };

  const getPositionConfig = async () => {
    try {
      const response = await PositionApi.getPositionConfig("positionId", positionId);
      if (response?.success) {
        setConfig(response?.positionConfig ?? {})
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getOrgApiKeys = async () => {
    try {
      const res = await SettingsApi.getApisList(orgId);
      if (res?.success) {
        const [activeApiKey] = res?.result?.filter(key => key.isActive);
        setActiveApiKey(activeApiKey?.apiKey);
      }
    } catch (e) {
      console.error(e?.message);
    }
  };

  const onCandidateInvite = async (values, setModal, setIsSubmitting) => {
    try {
      setIsSubmitting(true)
      // adding dnaId
      values.positionId = positionId;
      const hasThirdPartyLogin = config?.loginType === LOGIN_TYPE.THIRD_PARTY;
      const response = hasThirdPartyLogin ?
        await PositionApi.inviteThirdPartyUser(values) :
        await PositionApi.inviteUser(values);

      if (response?.success) {
        dispatch(getUsers({ positionId, filters }));
        setModal(false);
      } else {
        setInviteUserError(response?.message)
      }

      // Mixpanel Event
      Mixpanel.track(MIXPANEL_EVENTS.InvitedCandidate, {
        Name: values.name,
        Email: values.email,
        "Years of Experience": values.yearsOfExperience,
        Type: "Hiring",
        "Position Title": positionStats.data.name,
        Status: response?.success,
      });
    } catch (err) {
    } finally {
      setIsSubmitting(false)
    }
  };

  const mixpanelEventHandler = (event, userData) => {
    const props = {
      "Candidate Name": userData.name,
      "Position Title": positionStats.data.name,
      Type: "Hiring",
    };
    if (userData.fitScore) {
      props["Candidate Fit Score"] = userData.fitScore;
    }
    Mixpanel.track(event, props);
  };

  const statusChangeHandler = (userId, status) => {
    dispatch(updateUserStatus({ userId, status }));
  };

  // const sortHnadler = (sortFilters) => {
  //   const updatedFilters = { ...filters, sortFilters };
  //   setFilters(updatedFilters);
  //   dispatch(getUsers({ positionId, updatedFilters }));
  // };

  /**
   * Update the state for to be edited candidate for InviteUserForm.
   * @param {data} - data of candidate.
   */
  const editInvite = (data) => {
    setEditInviteData(data)
  }

  /**
   * Submit the edit form.
   * @param {values} - Values entered in the form.
   * @param {setModal} - Toggle the modal visibility after submission.
   * @param {setIsSubmitting} - Toggle the loading state of submit button of form.
   */
  const submitEditInvite = async (values, setModal, setIsSubmitting) => {
    try {
      setIsSubmitting(true)
      const response = await CandidateApi.updateCandidateInvite({ 
        userId: editInviteData.id, 
        payload: values,
        orgType,
        orgId,
       })
      if (response.success) {
        dispatch(getUsers({ positionId, filters }))
        setEditInviteData({})
        setModal(false)
      }
    } catch (err) {
    } finally {
      setIsSubmitting(false)
    }
  }

  /**
   * Submit the edit form.
   * @param {id} - linkId of the selected user.
   */
  const showCandidateFormData = (id) => {
    setCandidateFormDataLink(id)
  }

  /**
   * ===================
   * useCallback
   * ===================
   */

  /**
   * ===================
   * useEffects
   * ===================
   */
  useEffect(() => {
    (async () => {
      dispatch(getPositionStats(positionId));
      getPositionContraints();
      getPositionConfig();
      getOrgApiKeys();
    })()
    /**
   * Resetting position stats to avoid multiple performace api calls.
   * Position stats gives positionId which is used in the performance api.
   * If not resetted, the first call would be for older positionId and 
   * the second api call would be for the current positionId after the new stats are fetched.  
   */
    return () => {
      dispatch(resetPositionStats())
    }
  }, []);


  /**
   * Get scroll position in sessionStorage -> Post navigating back from report link.
   * 
   * Implementation -> viewGeneratedReport -> ReportGenerator.js
   *  */
  useEffect(() => {
    if (users.data.length && !initialDataLoadRef.current) {
      const windowWidth = window.innerWidth;
      /**
       * For width > 1268, two column-view, only right-portion is scrolled.
       * For width < 1268, single scrollable col, target: position-detail-conatiner. 
       *  */
      const containerSelector = windowWidth < 1268 ? ".position-detail-conatiner" : ".right-portion";
      const scrolledPosition = sessionStorage.getItem('trackUserListingScroll');
      if (!scrolledPosition) {
        initialDataLoadRef.current = true;
        return;
      }
      const scrolledWindow = document.querySelector(containerSelector);
      if (scrolledWindow) {
        initialDataLoadRef.current = true;
        // Clear sessionStorage post setting scroll position
        sessionStorage.removeItem('trackUserListingScroll');
        scrolledWindow.scrollTo({ top: scrolledPosition, behavior: "smooth" });
      }
    }
  }, [users]);

  /**
   * Fetch users only if filters are applied including page. 
   * To avoid extra api call with no params.
   */
  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      dispatch(getUsers({ positionId, filters }));
    }
    // const scrolledWindow = document.querySelector(".position-detail-conatiner");
    // if (scrolledWindow) {
    //   console.log('first', first)
    //   scrolledWindow.scrollTo(0, 0);
    // }
  }, [filters]);

  useEffect(() => {
    setPositionLoginType(config?.loginType ?? "")
  }, [config])

  return (
    <>
      <PageHelmet title="Position Details" />
      <div className="position-detail-conatiner">
        <div className="left-portion">
          <div className="content-wrapper">
            <div className="flex items-center back-wrapper">
              <TitleWithIcon
                icon={<GoBack />}
                title="BACK"
                className="cursor-pointer"
                titleNameStyle="text-18 weight-600"
                onClick={() => {
                  if (location.pathname.includes("mass")) {
                    history.push("/mass-hiring");
                  } else {
                    history.push("/hiring");
                  }
                }}
              />
            </div>
            {
              positionStats.hasData && (
                <OverallStats
                  // hidePerfomance
                  showSelfSignUpLink
                  activeStatus={positionStats?.data?.status}
                  id={positionStats.data.id}
                  key={positionStats.data.id}
                  icon={positionStats.data.mediaUrl || "/img/code-alt.png"}
                  name={positionStats.data.name}
                  created={positionStats.data.createdAt}
                  stats={positionStats.data.stats}
                  type={positionStats.data.parentPosition?.name}
                  onStatClick={() => { }}
                  isAnalyticsBtnVisible={config?.viewDashboard ?? false}
                  showPositionAssessmentDetails={true}
                  handleViewAnalytics={() => {
                    history.push(`/hiring/${positionId}/analytics`)
                  }}
                />
              )
            }
          </div>
        </div>
        <div className="right-portion">
          <div
            className={`filters-wrapper ${users?.data?.[0] || users?.loading || hasFilters ? "active" : ""
              }`}
          >
            <div className="page-navigation-box flex items-center justify-between pt-40 pb-24">
              <div className="count text-34 bold">
                {
                  !users.loading
                    ? <>{users?.count} {users?.count < users?.totalCount ? `out of ${users?.totalCount}` : ""} {users?.totalCount < 2 ? "Candidate" : "Candidates"}</>
                    : <></>
                }
              </div>
              {
                positionStats?.data?.status ?
                  <div className="second-half btn-box flex items-center gap-2">
                    <BulkUploader
                      config={config}
                      onSuccess={() => dispatch(getUsers({ positionId, filters }))}
                    />
                    <InviteUser
                      icon={<AddIcon />}
                      config={config}
                      onSubmit={onCandidateInvite}
                      error={inviteUserError}
                      setError={setInviteUserError}
                      editInviteData={editInviteData}
                      setEditInviteData={setEditInviteData}
                      submitEditInvite={submitEditInvite}
                    />
                  </div> : null
              }
            </div>
            <div className="top-row"></div>
            <div className="filter-row mt-16 mb-20">
              <div className={`first-half ${thirdPartyLogin ? "" : ""} flex items-center`}>
                {
                  thirdPartyLogin ?
                    <>
                      <SearchInput placeholder="Search by candidate name" />
                      <SearchInput placeholder="Search by candidate key" paramKey="candidateKey" />
                    </> :
                    <SearchInput placeholder="Search for candidate" />
                }
              </div>
              <div className="second-half">
                <Filters constraints={constraints} />
                {/* <div className="filter-box flex items-center"></div> */}
              </div>
            </div>
          </div>
          {users.loading ? (
            <UsersListingShimmer elementNumber={4} />
          ) :
            users.data?.length === 0 && positionStats.data.status ? (
              <div
                className={` ${hasFilters ? "" : "empty-state"
                  }  flex flex-col justify-center items-center relative`}
              >
                <InviteUser
                  emptyState={true}
                  config={config}
                  archived={
                    positionStats.data.status === POSITION_ARCHIVE_STATUS.CLOSE
                  }
                  showEmptyState={hasFilters}
                  icon={<AddIcon />}
                  onSubmit={onCandidateInvite}
                  onBulkInviteSuccess={() =>
                    dispatch(getUsers({ positionId, filters }))
                  }
                  error={inviteUserError}
                  setError={setInviteUserError}
                />
              </div>
            ) : (
              <>
                <Listing
                  positionActiveStatus={positionStats.data.status}
                  dataSource={users.data}
                  // onSort={sortHnadler}
                  headerLayout={thirdPartyLogin
                    ? HEADER_LAYOUT.THIRD_PARTY
                    : HEADER_LAYOUT.NON_THIRD_PARTY
                  }
                  dataLayout={thirdPartyLogin
                    ? HEADER_LAYOUT.THIRD_PARTY
                    : HEADER_LAYOUT.NON_THIRD_PARTY
                  }
                  onRowClick={(rowData) => { }}
                  getRowClassName={(rowData) => { }}
                  positionLoginType={positionLoginType}
                  headers={thirdPartyLogin ? [
                    "Name & Candidate ID",
                    "Contact Details",
                    "Last Updated",
                    "Status",
                  ] : [
                    "Name",
                    "Contact Details",
                    "Experience",
                    "Last Updated",
                    "Status",
                  ]}
                  dataKeys={thirdPartyLogin
                    ? DATA_KEYS.THIRD_PARTY({
                      activeApiKey,
                      positionLoginType,
                      positionStats,
                      orgId,
                      constraints,
                      dispatch,
                      removeInvitedUser,
                      statusChangeHandler,
                      mixpanelEventHandler,
                      showCandidateFormData: config?.customizableForm ? showCandidateFormData : null,
                      ...(
                        positionStats.data.status
                        && { editInvite }
                      ),
                      showCandidateReport: config?.sendReportToUser
                    }) : DATA_KEYS.NON_THIRD_PARTY({
                      positionStats,
                      orgId,
                      constraints,
                      dispatch,
                      removeInvitedUser,
                      statusChangeHandler,
                      mixpanelEventHandler,
                      showCandidateFormData: config?.customizableForm ? showCandidateFormData : null,
                      ...(
                        positionStats.data.status
                        && { editInvite }
                      ),
                      showCandidateReport: config?.sendReportToUser
                    })}
                />
              </>
            )}
          {/* pagination */}
          {users.totalPages > 1 && (
            <div className="pagination-box text-center">
              <Pagination
                totalElements={users.count}
                defaultPage={filters?.page || 1}
                pageSize={users.countPerPage}
              />
            </div>
          )}
        </div>
      </div>
      <CandidateFormData
        linkId={candidateFormDataLink}
        setCandidateFormDataLink={setCandidateFormDataLink}
      />
    </>
  );
}
