import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./DNAStatsCard.scss";
import { DUMMY_TRAITS } from "src/constants/globalConstants";
import DnaStats from "../Stats";
import TraitFillBlock from "src/components/Common/TraitFillBlock";
import PositionApi from "src/services/apis/positions";
import { getTraitBand } from "src/pages/OnePagerReport/oprations";
import DropdownMenu from "src/components/Common/DropdownMenu";
import { CSVLink } from "react-csv";
import { Input, Menu } from "antd";
import { DownloadOutlined, EditOutlined } from "@ant-design/icons";
import { generateCompanyDataCsv } from "src/utils/helperFunctions";
import { useExcelDownload } from "src/utils/hooks";
import { CheckIcon, CloseIcon, EmptyTraitsIcon } from "src/assets/icons";
import Toast from "src/components/Common/Toast";
export default function DNAStatsCard({
  id,
  name,
  stats = {},
  reportStatus,
  onClick,
  orgId,
  orgType
}) {
  const [roleTraits, setRoleTraits] = useState([]);
  const [positionName, setPositionName] = useState(name || "");
  const [isEditMode, setIsEditMode] = useState(false);

  const [menuVisibility, setMenuVisiblity] = useState(false);
  const [companyUsersData, setCompanyUsersData] = useState(null);
  const csvLink = useRef();
  useExcelDownload({xlsxLink: csvLink, data: companyUsersData, setData: setCompanyUsersData, fileName: `${positionName.replace(/ /g, '_')}_users_data`});
  const dropdownMenuRef = useRef();
  const toggleMenuView = (visible) => setMenuVisiblity(visible);

  useEffect(() => {
    async function getTraitsData() {
      const res = await PositionApi.getTraits({ positionId: id });
      setRoleTraits(res.relevant);
    }
    if (reportStatus === "ready") getTraitsData();
  }, [reportStatus]);

  const updatePosition = useCallback(
    async (e) => {
      e.stopPropagation();
      const payload = {
        name: positionName,
      };
      const response = await PositionApi.updatePosition({
        positionId: id,
        payload,
        orgId,
        orgType
      });
      if (response.success) {
        Toast("success", "Position updated successfully!");
        setIsEditMode(false);
      }
    },
    [id, positionName]
  );

  const userMenu = (
    <div className={`optionmenu ${menuVisibility ? "open" : ""}`}>
      <Menu onClick={() => toggleMenuView(false)}>
      <Menu.Item
          className="semibold text-archieve"
          key="0"
          icon={<EditOutlined />}
          onClick={() => {
            setIsEditMode(true);
          }}
        >
          {"Edit Position Title"}
        </Menu.Item>
        <Menu.Item
          className="semibold text-archieve"
          key="1"
          icon={<DownloadOutlined />}
          onClick={() => {
            getCompanyData()
          }}
        >
          Download Data
        </Menu.Item>
      </Menu>
    </div>
  );

  async function getCompanyData() {
    const response = await generateCompanyDataCsv({ orgId, positionId: id })
    if (response) setCompanyUsersData(response);
  }

  return (
    <div
      className={`dna-stats-card relative cursor-pointer`}
      onClick={() => onClick(id)}
    >
      <div className="flex justify-between items-start">
        {/* <div className="position-title">{name}</div> */}
        <div className="text-black semibold text-26 mb-6 line-30 w-90 break-word">
          {isEditMode ? (
            <div className={"flex justify-between align-center mt-10"}>
              <Input
                key={id}
                value={positionName}
                onChange={(e) => setPositionName(e.target.value)}
                autoFocus={true}
                className={"edit-name"}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.keyCode === 13) {
                    updatePosition(e);
                  }
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
              <span className={"save-edit-options"}>
                <CloseIcon
                  className="close-button"
                  width={32}
                  height={32}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsEditMode(false);
                  }}
                />
                <CheckIcon
                  className={"check-icon"}
                  width={32}
                  height={32}
                  onClick={updatePosition}
                />
              </span>
            </div>
          ) : (
            positionName
          )}
        </div>
        {
          !isEditMode &&
          <div
            onClick={(e) => {
              e.stopPropagation();
              dropdownMenuRef.current.toggleDropdownMenu(false)
            }}
          >
            <DropdownMenu
              ref={dropdownMenuRef}
              onVisibleChange={toggleMenuView}
              menu={userMenu} />
          </div>
        }
      </div>
      <div className="mt-20">
        <DnaStats stats={stats} />
        {roleTraits.length > 0 ? (
          <div className="mt-20">
            <h5 className="trait-heading">Role Relevant Traits</h5>
            <div className="traits-container">
              {roleTraits.map(({ id, displayName, orgData, industryData }) => {
                const { median: score } = orgData;
                const { upperBound: ub, median, lowerBound: lb } = industryData;
                const fillPercentage = score * 10;
                const { color, textColor } = getTraitBand({
                  ub,
                  lb,
                  median,
                  score,
                });
                return (
                  <TraitFillBlock
                    key={id}
                    fillPercentage={fillPercentage}
                    color={color}
                    average={median * 10}
                    whiteSpacing={0.5}
                    className="trait-bar"
                  >
                    <h4 className="trait-name" style={{ color: textColor }}>
                      {displayName}
                    </h4>
                  </TraitFillBlock>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="traits-container empty">
            <div className="info">
              <span>
                <EmptyTraitsIcon />
              </span>
              <p>
                DNA scores are
                <br />
                not available yet...
              </p>
            </div>
            <div className="blur-container">
              {DUMMY_TRAITS.map(({ score, traitColors }) => (
                <TraitFillBlock
                  fillPercentage={score * 10}
                  color={traitColors.color}
                  className="trait-bar"
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <div
        ref={csvLink}
        onClick={(e) => {
         e.stopPropagation()
        }}
      >
      </div>
    </div>
  );
}

DNAStatsCard.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  stats: PropTypes.object,
  reportStatus: PropTypes.string,
  onClick: PropTypes.func,
};

DNAStatsCard.defaultProps = {
  id: null,
  name: null,
  stats: {},
  reportStatus: null,
  onClick: () => { },
};
