import React, { useEffect, useState } from "react";
import "./OverallStats.scss";
import TraitFillBlock from "src/components/Common/TraitFillBlock";
import { FIT_BANDS } from "src/pages/OnePagerReport/constant";
import PositionApi from "src/services/apis/positions";
import { roundOff } from "src/pages/PdfReport/operations";
import DnaStats from "../Stats";
import {
  copyTextToClipboard,
  getDefaultFormattedDate,
  getPositionLoginLink,
  getPositionSignupLink,
  getRelativeTimeFormat,
  pluralizeWord
} from "src/utils/helperFunctions";
import { Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import Toast from "src/components/Common/Toast";
import { ORGANIZATION_TYPES, PUBLIC_LINK_TYPES } from "src/constants/globalConstants";
import { storage } from "src/services/config/storage";
import { CopyTwoTone, EditOutlined } from "@ant-design/icons";
import { Average, CloseIcon, EmptyTraitsIcon } from "src/assets/icons";
import { ArrowRightOutlined } from "@ant-design/icons";
import moment from "moment";
import InviteScheduler from "src/forms/InviteScheduler";
import WithToolTip from "src/components/Common/WithToolTip";

const ScheduledInvites = (props) => {
  const { positionId } = props;
  const [scheduledInvite, setScheduledInvite] = useState(null);
  const [scheduleModal, setShowScheduleModal] = useState(false);

  const getScheduledInvite = async () => {
    const response = await PositionApi.getScheduledInvite(positionId);
    if (response.success) {
      const { scheduleDetails } = response;
      const momentDateTimeUTC = moment.utc(scheduleDetails?.triggerAt); // Parse UTC date-time string
      const localTimeZoneOffset = moment().utcOffset();
      const momentDateTimeLocal = momentDateTimeUTC.add(localTimeZoneOffset, 'minutes');
      const currentSchedule = momentDateTimeLocal.format('ddd, DD MMM YYYY, hh:mm A');
      setScheduledInvite(currentSchedule);
    } else {
      setScheduledInvite(null);
    }
  };

  useEffect(() => {
    if (positionId) {
      getScheduledInvite();
    }
  }, [positionId]);

  const handleSchedulerOps = () => {
    getScheduledInvite();
    setShowScheduleModal(false);
  };

  return scheduledInvite ? (
    <>
      <div className="flex flex-col items-start mt-6">
        <p className="mb-2">Invites to go out at:</p>
        <div className="flex items-center">
          <p className="color-blue weight-600">{scheduledInvite}</p>
          <WithToolTip
            title={"Edit Schedule"}
            showToolTip={true}
          >
            <EditOutlined className="ml-8 text-16" onClick={() => setShowScheduleModal(true)} />
          </WithToolTip>
        </div>
      </div>
      <InviteScheduler
        showModal={scheduleModal}
        setShowModal={setShowScheduleModal}
        positionId={positionId}
        onSuccess={handleSchedulerOps}
      />
    </>
  ) : <></>;
}

const PublicSignup = (props) => {
  const { activeStatus, showSelfSignUpLink, orgId, id } = props;
  return (
    <>
      {
        (activeStatus && showSelfSignUpLink) ?
          // Same component re-used for Public Signup (self-register), Public Login (self-login)
          Object.values(PUBLIC_LINK_TYPES).map((value) => (
            <div
              key={value}
              className="flex flex-col items-center mt-8 self-sign-up"
            >
              <div className="header flex items-center justify-between">
                <span className="text-16 weight-700">
                  {value === PUBLIC_LINK_TYPES.PUBLIC_SIGNUP ? "Public Signup" : "Public Login"}
                </span>
                <p
                  className="flex items-center link"
                  onClick={() => {
                    if (value === PUBLIC_LINK_TYPES.PUBLIC_SIGNUP) {
                      copyTextToClipboard(getPositionSignupLink(ORGANIZATION_TYPES.COMPANY, orgId, id));
                    } else {
                      copyTextToClipboard(getPositionLoginLink(id));
                    }
                    Toast("success", "Link Copied Successfully.", "Link copied");
                  }}
                >
                  <CopyTwoTone
                    className="mr-2 weight-900"
                    twoToneColor="#FF864E"
                  />
                  <span className="text-14 weight-500">Copy link</span>
                </p>
              </div>
              <span className="desc mt-16 text-14 weight-400">
                {`Share this link with 
                ${value === PUBLIC_LINK_TYPES.PUBLIC_SIGNUP ? 'anyone to self register' : 'invited candidates to login'}
                & start gameplay.
              `}
              </span>
            </div>
          )) : null
      }
    </>
  )
};

export const OverallStats = (props) => {
  const history = useHistory();
  const {
    id,
    name,
    created,
    stats = {},
    onStatClick,
    hidePerfomance = false,
    showSelfSignUpLink = false,
    activeStatus,
    renderComponent = "",
    isAnalyticsBtnVisible = false,
    showPositionAssessmentDetails = false,
    handleViewAnalytics = () => { }
  } = props;
  const [performance, setPerformance] = useState(null);

  useState(() => {
    async function fetchPerformance() {
      try {
        const res = await PositionApi.getDnaPerformance({
          positionId: id,
        });
        if (res.success) setPerformance(res.bands);
        else setPerformance([]);
      } catch (e) {
        console.log(e);
      }
    }
    !hidePerfomance && fetchPerformance();
  }, [hidePerfomance]);


  const showEmptyState = !performance || performance.length === 0 || performance.every(elem => !elem?.percentage);
  const orgId = storage.get.organizationInfo().organizationId;

  const publicSignupProps = {
    activeStatus, showSelfSignUpLink, orgId, id
  };

  const onViewDetails = () => {
    window.open(`/position-details/${orgId}/${id}`, "_blank");
  }

  const groupName = renderComponent === "myDnaDetail" ? "Team's" : "Pool's";

  return (
    <div
      className={`dna-info-card 
        ${renderComponent === "myDnaDetail" ?
          `my-dna-detail ${(activeStatus && showSelfSignUpLink) ? "show-pubilc-links" : ""}` : ""}
      `}
    >
      <div className="flex justify-between items-center">
        <div className="position-title">
          <div className="flex flex-col items-start">
            <div className="position">{name}</div>
            {
              showPositionAssessmentDetails &&
              <p className="link-underline flex items-center gap-1 mt-2" onClick={onViewDetails}>
                <span className="text-14 weight-500">
                  {"View Position Details"}
                </span>
                <span className="relative top-[1]">
                  <ArrowRightOutlined />
                </span>
              </p>
            }
            <ScheduledInvites positionId={id} />
          </div>
          <CloseIcon
            className="close-button"
            onClick={() => history.goBack()}
          />
        </div>
      </div>
      <div className="public-links-container">
        <PublicSignup {...publicSignupProps} />
      </div>
      {
        isAnalyticsBtnVisible
          ? <button onClick={handleViewAnalytics} className="view-analytics-btn mt-16 w-full py-8 capitalize cursor-pointer text-18">view analytics</button>
          : <></>
      }
      <DnaStats stats={stats} className="overall-stats-tablet" />
      {!hidePerfomance && (
        <div className="traits-container relative">
          {showEmptyState && (
            <div className="empty-state absolute">
              <span>
                <EmptyTraitsIcon />
              </span>
              <p>Your {groupName} performance not available yet...</p>
            </div>
          )}
          {!showEmptyState && (
            <h1 className="overall-heading">Your {groupName} Performance</h1>
          )}
          <div className={`performance-bars ${showEmptyState ? "empty" : ""}`}>
            {!showEmptyState
              ? performance.map((item, index) => {
                const { color, description } =
                  Object.values(FIT_BANDS)[index];
                return (
                  <Tooltip title={description} placement="right">
                    <TraitFillBlock
                      key={item.category}
                      fillPercentage={item.percentage}
                      color={color}
                      className="bar"
                      whiteSpacing={0.5}
                      blankColor="#F5F5F5"
                      onClick={() => onStatClick(true, index)}
                    >
                      <h2 className="percentage">
                        {roundOff(item.percentage)}%
                      </h2>
                      <p className="text">
                        {item.users}{" "}
                        {pluralizeWord("Member", "Members", item.users)}
                      </p>
                    </TraitFillBlock>
                  </Tooltip>
                );
              })
              : Object.values(FIT_BANDS).map((item, index) => (
                <TraitFillBlock
                  fillPercentage={70 - 15 * (index + 1)}
                  color={item.color}
                  className="bar empty"
                  whiteSpacing={0.5}
                ></TraitFillBlock>
              ))}
          </div>
        </div>
      )}
      <div className="created-date">
        <span>
          Created {getDefaultFormattedDate(created, "DD MMM YYYY")} •{" "}
          {getRelativeTimeFormat(created)}
        </span>
        {/* <div className="legends">
          <Average />
          <span>Industry Average</span>
        </div> */}
      </div>
    </div>
  );
};
