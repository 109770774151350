import React, { useState, useEffect, useMemo } from "react";
import SectionContentAnswer from "../SectionContentAnswer";
import SectionContentHeader from "../SectionContentHeader";
import {
  UpOutlined,
  DownOutlined,
} from '@ant-design/icons';
import { QUESTIONS, QUIZ_DISPLAY_SECTIONS } from "../../constant";
import { GetMediaComponent } from "../utils";

export const SectionContentItem = (props) => {

  const { data, serial, activeSection } = props;

  const extractDataForActiveSection = (data) => {
    if (activeSection === QUIZ_DISPLAY_SECTIONS.MCQ) {
      const { question, options, answer } = data;
      const { question: questionText } = question;
      const markedCorrect = options.some((option) => option?.id === answer?.optionId && option?.correct);
      const mediaType = question?.mediaIds ? question?.mediaIds[0]?.mediaType : null;
      const medialink = question?.mediaIds ? question?.mediaIds[0]?.mediaUrl : null;

      const audioSrc = mediaType === "audio" ? medialink : null;
      const videoSrc = mediaType === "video" ? medialink : null;
      const imageSrc = mediaType === "image" ? medialink : null;

      return [questionText, markedCorrect, imageSrc, audioSrc, videoSrc];
    } else {
      const { description, testcases, submission: { score } } = data;
      const maxScore = testcases.reduce((acc, { points }) => acc + points, 0);
      const markedCorrect = score === maxScore;
      return [description, markedCorrect];
    }
  };

  let [questionText, markedCorrect, imgSrc = '', audioSrc = '', vidSrc = ''] = useMemo(() =>
    extractDataForActiveSection(data), [data]);

  const textDisplayLength = 300;
  const [displayQuestion, setDisplayQuestion] = useState(false);

  const testHtml = /<\/?[a-z][\s\S]*>/i.test(questionText);
  const checkQuestionLength = questionText?.length > textDisplayLength;

  // Sentence completion
  // Optional Chaing after data since programming questions have different structure
  const hasSentenceCompletion = data?.question?.type.includes(QUESTIONS.SENTENCE_COMPLETION);
  if (hasSentenceCompletion) {
    questionText = questionText.split(" ");
  }

  useEffect(() => {
    if (checkQuestionLength) setDisplayQuestion(false);
  }, [questionText]);

  return (
    <section className="section-content-item">
      <SectionContentHeader
        activeSection={activeSection}
        markedCorrect={markedCorrect}
        data={data}
      />
      {
        hasSentenceCompletion ? (
          <pre className="question-content wrap-whitespace line-break-any">
            {`${serial}. `}
            {
              questionText.map((elem, index) => {
                if (elem.includes('[blank]')) {
                  elem = elem.replaceAll('[blank]', '________')
                }
                return (<span className="px-4 word">{elem}</span>)
              })
            }
          </pre>
        ) : testHtml ?
          (
            <div
              className="question-content"
              dangerouslySetInnerHTML={{
                __html: displayQuestion
                  ? `<div style="display: flex; gap: 0 4px;"><span>${serial}. </span><div>${questionText}</div></div>`
                  : `<div style="display: flex; gap: 0 4px;"><span>${serial}. </span><div>${questionText?.slice(0, textDisplayLength)}${checkQuestionLength ? '...' : ''}</div></div>`
              }}
            />
          ) : (
            <pre className="question-content wrap-whitespace">
              {`${serial}. ` + (displayQuestion ?
                (questionText) : (questionText?.slice(0, textDisplayLength) + (checkQuestionLength ? '...' : '')))
              }
            </pre>
          )
      }
      <GetMediaComponent
        vidSrc={vidSrc}
        audioSrc={audioSrc}
        audioSrcLabel={"Question"}
        imgSrc={imgSrc}
      />
      {
        checkQuestionLength &&
        <div className="display-question flex align-center mt-3" onClick={() => setDisplayQuestion(prev => !prev)}>
          <span>View {displayQuestion ? 'Less' : 'More'} </span>
          <span className="display-question-icon ml-6">{displayQuestion ? <UpOutlined /> : <DownOutlined />}</span>
        </div>
      }
      <SectionContentAnswer
        activeSection={activeSection}
        markedCorrect={markedCorrect}
        answerData={data}
      />
    </section>
  );
};