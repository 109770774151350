import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./MyDnaDetail.scss";
import TitleWithIcon from "src/components/Common/TitleWithIcon/TitleWithIcon";
import SearchInput from "src/components/Common/SearchInput";
import Pagination from "src/components/Common/PrimaryPagination";
import PageHelmet from "src/components/Common/PageHelmet";
import Filters from "src/components/Common/Filters";
import UsersListingShimmer from "src/components/Common/Skeletons/UsersListingShimmer/UsersListingShimmer";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUsersByPosition,
  getDnaStats,
  removeInvitedUser,
} from "src/store/DNA/asyncActions";
import Listing from "src/components/Common/Listing";
import {
  getRelativeTimeFormat,
  getDefaultFormattedDate,
} from "src/utils/helperFunctions";
import ReportGenerator from "src/components/Common/ReportGenerator";
import {
  ORGANIZATION_TYPES,
  DNA_MENU_TAB,
  REPORT_STATUS,
  MIXPANEL_EVENTS,
} from "src/constants/globalConstants";
import { updateUserStatus } from "src/store/DNA/reducer";
import { storage } from "src/services/config/storage";
import Tab from "src/components/Common/Tab";
import DNATraits from "src/pages/MyDna/DNATraits";
import PositionApi from "src/services/apis/positions";
import { OverallStats } from "../OverallStats/OverallStats";
import TraitsDrawer from "src/pages/MyDna/TraitsDrawer";
import CandidateApi from "src/services/apis/candidate";
import BlankState from "src/components/Common/BlankState";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Mixpanel } from "src/services/mixpanel";
import { useUrlFilter } from "src/utils/hooks";
import InviteUser from "src/forms/InviteUserForm";
import DnaApi from "src/services/apis/dna";
import { AddIcon, Average, GoBack } from "src/assets/icons";

export default function MyDnaDetail() {
  /**
   * ===================
   * props from 'parent'
   * ===================
   */

  /**
   * ===================
   * props from redux
   * ===================
   */

  const {
    users = [],
    count,
    totalPages,
    loading,
    countPerPage,
  } = useSelector(({ dna }) => dna.usersByPosition);
  const dnaStats = useSelector((state) => state.dna.dnaStats);
  const [menuKey, setMenuKey] = useState(DNA_MENU_TAB.TRAITS);
  const orgId = storage.get.organizationInfo().organizationId;

  /**
   * ===================
   * constant variables
   * ===================
   */

  const history = useHistory();
  const dispatch = useDispatch();
  const { dnaId } = useParams();
  const location = useLocation();

  /**
   * ===================
   * useStates
   * ===================
   */
  const [filters, setFilters] = useUrlFilter();
  const [constraints, setConstraints] = useState({});
  const [showDrawer, setShowDrawer] = useState(false);
  const [defaultDrawerTab, setDefaultDrawerTab] = useState(0);
  const [candidates, setCandidates] = useState(null);
  const [traits, setTraits] = useState(null);
  const [config, setConfig] = useState({});
  const [inviteUserError, setInviteUserError] = useState(null);
  const [inviteUserModalVisible, setInviteUserModalVisible] = useState(false)

  /**
   * ===================
   * useRefs
   * ===================
   */

  /**
   * ===================
   * computed variables
   * ===================
   */

  /**
   * ===================
   * custom hooks
   * ===================
   */

  /**
   * ===================
   * functions
   * ===================
   */

  const handleMenu = (key) => {
    const search = queryString.parse(location.search);
    search.tab = key;
    history.push({ search: queryString.stringify(search) });
    setMenuKey(key);
  };

  const getAllUsersByPositionId = async (filters) => {
    dispatch(getAllUsersByPosition({ dnaId, filters }));
  };

  const getPositionContraints = async () => {
    const constraints = await PositionApi.getContraints(dnaId);
    if (constraints?.success) {
      setConstraints(constraints.result?.band?.data);
    }
  };

  const handleDrawer = (showDrawer = false, tabIndex = 0) => {
    setDefaultDrawerTab(tabIndex);
    setShowDrawer(showDrawer);
  };

  const onFilterChange = ({ tab, ...filters }) => {
    setFilters(filters);
  };

  const mixpanelEventHandler = (event, userData, extraProps = {}) => {
    const props = {
      ...extraProps,
      "Candidate Name": userData.name,
      "Position Title": dnaStats.data.name,
      Type: "DNA",
    };
    if (userData.fitScore) {
      props["Candidate Fit Score"] = userData.fitScore;
    }
    Mixpanel.track(event, props);
  };

  const statusChangeHandler = (userId, status) => {
    dispatch(updateUserStatus({ userId, status }));
  };

  const onCandidateInvite = async (values, setModal) => {
    // adding dnaId
    values.positionId = dnaId;
    const response = await DnaApi.inviteUser(values);

    if (response?.success) {
      dispatch(getAllUsersByPosition({ dnaId, filters }));
      // Toast("success", "Invitation sent successfully!");
      setModal(false);
    } else {
      setInviteUserError(response?.message);
    }

    // Mixpanel Event
    Mixpanel.track(MIXPANEL_EVENTS.InvitedCandidate, {
      Name: values.name,
      Email: values.email,
      "Years of Experience": values.yearsOfExperience,
      Type: "DNA",
      "Position Title": dnaStats.data.name,
      Status: response?.success,
    });
  };

  const getPositionConfig = async () => {
    try {
      const response = await PositionApi.getPositionConfig("positionId", dnaId);
      if (response?.success) {
        setConfig(response?.positionConfig ?? {})
      }
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * ===================
   * useCallback
   * ===================
   */

  /**
   * ===================
   * useEffects
   * ===================
   */
  useEffect(() => {
    dispatch(getDnaStats(dnaId));
    getPositionConfig();
    getPositionContraints();
    async function fetchCandidates() {
      const res = await CandidateApi.getCandidates({ positionId: dnaId });
      if (res?.success) {
        const { success, ...data } = res;
        setCandidates(data);
      }
    }
    async function fetchTraits() {
      const res = await PositionApi.getTraits({ positionId: dnaId });
      setTraits(res);
    }
    fetchTraits();
    fetchCandidates();
  }, []);

  /**
   * First if condition to make sure the list is fetched only when people tab is visited.
   * Removing tab key for API payload.
   * Second if condition to make sure the list is fetched only if filter is applied including page.
   */
  useEffect(async () => {
    if (filters?.tab === DNA_MENU_TAB.PEOPLE) {
      delete filters?.tab
      if (Object.keys(filters).length > 0) {
        dispatch(getAllUsersByPosition({ dnaId, filters }));
        const scrolledWindow = document.querySelector(".mydna-container");
        if (scrolledWindow) {
          scrolledWindow.scrollTo(0, 0);
        }
      }
    }
  }, [filters]);

  useEffect(() => {
    const { tab } = queryString.parse(location.search);
    if (Object.values(DNA_MENU_TAB).includes(tab)) setMenuKey(tab);
  }, [location.search]);

  return (
    <>
      <PageHelmet title="My Teams" />
      <div className="mydna-container mydna-detail-container">
        <div className="left-portion">
          <div className="content-wrapper">
            <div className="flex items-center back-wrapper">
              <TitleWithIcon
                icon={<GoBack />}
                title="Back"
                className="cursor-pointer"
                titleNameStyle="text-18 weight-600"
                onClick={() => history.push("/myteam")}
              />
            </div>
            {dnaStats.hasData && (
              <OverallStats
                hidePerformance
                id={dnaStats.data.id}
                key={dnaStats.data.id}
                icon={dnaStats.data.mediaUrl || "/img/code-alt.png"}
                name={dnaStats.data.name}
                created={dnaStats.data.createdAt}
                stats={dnaStats.data.stats}
                type={dnaStats.data.parentPosition?.name}
                onStatClick={handleDrawer}
                renderComponent="myDnaDetail"
                activeStatus={dnaStats?.data?.status}
                showSelfSignUpLink
              />
            )}
          </div>
        </div>
        <div className="right-portion">
          <div className="menu-tab">
            <Tab
              data={[
                {
                  key: DNA_MENU_TAB.TRAITS,
                  // hotfix (Prod) -> [UB-1388] Filter traits with orgData === null
                  count: traits?.success
                    ? (traits?.relevant?.filter(({ orgData }) => orgData !== null).length ?? 0) +
                    (traits?.other?.filter(({ orgData }) => orgData !== null).length ?? 0)
                    : "",
                  name: "Traits",
                },
                {
                  key: DNA_MENU_TAB.PEOPLE,
                  count: count ? count : "",
                  name: "People",
                },
              ]}
              titleClassName="text-20"
              onClick={(key) => handleMenu(key)}
              currentKey={menuKey}
            />
            {menuKey === DNA_MENU_TAB.TRAITS && traits?.success && (
              <div className="legends">
                <Average />
                <span>Industry Average</span>
              </div>
            )}
            {menuKey === DNA_MENU_TAB.PEOPLE && (
              <div className="mb-10 btn-responsive">
                <InviteUser
                  dna
                  icon={<AddIcon />}
                  config={config}
                  onSubmit={onCandidateInvite}
                  error={inviteUserError}
                  setError={setInviteUserError}
                  isModalActiveFromParent={inviteUserModalVisible}
                  setModalActiveFromParent={setInviteUserModalVisible}
                />
              </div>
            )}
          </div>
          {menuKey === DNA_MENU_TAB.PEOPLE ? (
            <>
              {users.length === 0 && Object.keys(filters ?? {}).length === 0 ? (
                <BlankState
                  className="blank-message"
                  message={"No members added yet"}
                  onClick={(e) => {
                    e.stopPropagation()
                    setInviteUserModalVisible(true)
                  }}
                />
              ) : (
                <>
                  <div className="filter-row mt-20 mb-20">
                    <div className="first-half">
                      <SearchInput placeholder="Search" />
                    </div>
                    <div className="second-half">
                      <Filters constraints={constraints} isHiringFilterVisible={false} />
                    </div>
                  </div>
                  {loading ? (
                    <UsersListingShimmer elementNumber={4} />
                  ) : (
                    <Listing
                      dataSource={users}
                      headerLayout={"1fr 0.8fr repeat(2, 0.8fr)"}
                      dataLayout={"1fr 0.8fr repeat(2, 0.8fr)"}
                      onRowClick={(rowData) => { }}
                      getRowClassName={(rowData) => { }}
                      headers={[
                        "Name & Email",
                        "Experience",
                        "Last Updated",
                        "Status",
                      ]}
                      EmptyComponent={
                        <h2 className="mt-160 text-34 semibold text-center">
                          No results found for this search
                        </h2>
                      }
                      dataKeys={[
                        {
                          name: "name",
                          render: (data) => {
                            return (
                              <div className="user-details">
                                <div
                                  className="user-name text-16 line-22 mb-6 text-dark weight-600 text-clip"
                                  title={data.name}
                                >
                                  {data.name}
                                </div>
                                <div
                                  className="user-email text-14 line-22 text-dark weight-400 opacity-7 text-clip"
                                  title={data.email}
                                >
                                  {data.email}
                                  <span className="mobile-visible">
                                    {data.expertiseLevel
                                      ? ` • ${data.expertiseLevel
                                        ?.split("-")
                                        ?.join(" ")}`
                                      : null}
                                  </span>
                                </div>
                                <div
                                  className="text-14
                                line-22 text-dark weight-400 opacity-7 text-clip mt-8"
                                  title={data?.phone}
                                >
                                  {data?.phone ? `${data?.phone}` : "-"}
                                </div>
                              </div>
                            );
                          },
                        },
                        {
                          name: "experience",
                          render: (data) => {
                            return (
                              <div className="flex mobile-hide">
                                <div className="">
                                  <div className="experience text-14 line-22 capitalize">
                                    {data.expertiseLevel?.split("-")?.join(" ")}
                                  </div>
                                  <div className="text-14 line-22 text-dark weight-400 opacity-7">
                                    {data.yearsOfExperience !== null
                                      ? `${data.yearsOfExperience} ${data.yearsOfExperience > 1
                                        ? "years"
                                        : "year"
                                      }`
                                      : null}
                                  </div>
                                </div>
                              </div>
                            );
                          },
                        },
                        {
                          name: "date invited",
                          render: (data) => {
                            return (
                              <div className="flex flex-col items-start mobile-order-3 mobile-flex-row">
                                <div className="text-14 line-22 capitalize mobile-hide">
                                  {getDefaultFormattedDate(
                                    data.updatedAt,
                                    "MMM D, YYYY"
                                  )}
                                </div>
                                <div className="text-14 line-22 text-dark weight-400 opacity-7 mobile-hide">
                                  {getRelativeTimeFormat(data.updatedAt)}
                                </div>
                                <div className="text-16 line-22 text-black created mobile-visible">
                                  {getDefaultFormattedDate(
                                    data.updatedAt,
                                    "MMM D, YYYY"
                                  )}{" "}
                                  • {getRelativeTimeFormat(data.updatedAt)}
                                </div>
                              </div>
                            );
                          },
                        },
                        {
                          name: "status",
                          render: (data) => {
                            return (
                              <ReportGenerator
                                candidateInTeam
                                positionActiveStatus={data.status}
                                linkId={data?.linkId}
                                userId={data?.id}
                                reportStatus={data?.reportStatus}
                                userStatus={data?.status}
                                orgId={orgId}
                                orgType={ORGANIZATION_TYPES.COMPANY}
                                hiringStatus={data?.hiringStatus}
                                disqualified={data?.disqualified}
                                disqualifyReason={data?.disqualifyReason}
                                constraints={constraints}
                                fitScore={data?.fitScore}
                                removeUser={(payload) =>
                                  dispatch(removeInvitedUser(payload))
                                }
                                logMixpanelEvent={(event, props) =>
                                  mixpanelEventHandler(event, data, props)
                                }
                                onChangeOfStatus={statusChangeHandler}
                              />
                            );
                          },
                        },
                      ]}
                    />
                  )}
                </>
              )}
              {/* pagination */}
              {totalPages > 1 && (
                <div className="pagination-box text-center">
                  <Pagination
                    totalElements={count}
                    defaultPage={filters?.page || 1}
                    pageSize={countPerPage}
                  />
                </div>
              )}
            </>
          ) : (
            <DNATraits positionId={dnaId} {...traits} />
          )}
          <TraitsDrawer
            data={candidates}
            defaultDrawerTab={defaultDrawerTab}
            onClose={() => handleDrawer()}
            visible={showDrawer}
          />
        </div>
      </div>
    </>
  );
}
