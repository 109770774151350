import styles from "./styles.module.scss";
import ModuleCard from "../ModuleCard";
import { getTimeForAudioTypeQuizModule } from "src/utils/helperFunctions.js";

const AssessmentComposition = ({
    readOnly,
    jobTitle = "",
    readOnlyData,
    validTraits = [],
    unberryPositionId,
    hideDelete = false,
}) => {
    
    if (readOnly) {
        return (
            <div className={styles.assessmentComposition}>
                <div className={styles.header}>
                    <div className={styles.headerText}>
                        <p className={styles.title}>
                            Preview the Assessment Experience
                        </p>
                        <div className={styles.subTitle}>
                            Your assessment will have{" "}
                            {readOnlyData?.length} modules.
                        </div>
                    </div>
                    <div className={styles.timeContainer}>
                        <span className={styles.clockIcon}>
                            {/* <ClockBlack /> */}
                        </span>
                        <p className={styles.timeText}>
                            Total Time:{" "}
                            {(
                                readOnlyData?.reduce((acc, el) => {
                                    const moduleTime =
                                        getTimeForAudioTypeQuizModule(el);
                                    return acc + (+moduleTime || 0);
                                }, 0) / 60
                            ).toFixed(0)}{" "}
                            Mins
                        </p>
                    </div>
                </div>
                {readOnlyData?.map((el, i) => {
                    const quizConfigModule = ["mcq"].includes(el.type);
                    const codingConfigModule = ["code-config"].includes(
                        el.type
                    );

                    const getModuleId = quizConfigModule
                        ? el?.sampleQuiz?.id
                        : codingConfigModule
                        ? el?.sampleProblem?.id
                        : el?.id;

                    const moduleTime = getTimeForAudioTypeQuizModule(el);

                    return (
                        <ModuleCard
                            key={i}
                            traits={el.traits}
                            moduleTitle={el.name}
                            moduleDesc={el.description}
                            moduleType={el.type}
                            moduleLevels={el.levels}
                            time={moduleTime}
                            quesCount={el.questionCount}
                            previewLink={el.link}
                            validTraits={validTraits}
                            mediaUrl={el?.mediaUrl}
                            id={getModuleId}
                            showModuleDeleteAction={
                                readOnlyData?.length > 1 && !hideDelete
                            }
                            unberryPositionId={unberryPositionId}
                        />
                    );
                })}
            </div>
        );
    }
};

export default AssessmentComposition;
