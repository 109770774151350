import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./constant";
import {
  setPositions,
  deletePosition,
  archievePosition,
  getUsers,
  getPositionStats,
  updateCandidateHiringStatus,
  removeInvitedUser, updatePositions
} from "./asyncActions";
import { POSITION_ARCHIVE_STATUS } from "src/pages/Positions/constants";

export const positionSlice = createSlice({
  name: "positions",
  initialState,
  reducers: {
    reset: () => initialState,
    updateUserStatus: (state, { payload }) => {
      const { userId, status } = payload;
      state.users.data = state.users.data.map((user) => {
        return user.id === userId ? { ...user, status } : user;
      });
    },
    updateUser: (state, { payload }) => {
      const { userId, updatedEmail } = payload;
      state.users.data = state.users.data.map((user) => {
        return user.id === userId ? { ...user, email: updatedEmail } : user;
      });
    }
  },
  extraReducers: {
    [setPositions.fulfilled]: (state, action) => {
      if (!action.payload) return;
      state.list.data = action.payload.result;
      state.list.open = action.payload.open;
      state.list.archived = action.payload.archived;
      state.list.loading = false;
    },
    [setPositions.pending]: (state, action) => {
      state.list.loading = true;
    },
    [updatePositions.fulfilled]: (state, action) => {
      const { positionId, payload: { positionOpen } } = action?.payload;
      // Filter position list on toggle of position status (active/archive)
      state.list.data = state.list.data.filter((position) => position?.id !== positionId);
      // Reset count for open/archived positions based positionOpen 
      if (!positionOpen) {
        state.list.archived++;
        state.list.open--;
      } else {
        state.list.archived--;
        state.list.open++;
      }
    },
    [deletePosition.fulfilled]: (state, action) => {
      state.list.data = [...state.list.data].filter(
        (position) => position.id !== action.payload
      );
    },
    [archievePosition.fulfilled]: (state, action) => {
      const [positionId, archiveStatus] = action.payload;
      state.list.data = [...state.list.data].filter(
        (position) => position.id !== positionId
      );

      if (archiveStatus === POSITION_ARCHIVE_STATUS.OPEN) {
        state.list.archived = state.list.archived - 1;
        state.list.open = state.list.open + 1;
      } else {
        state.list.archived = state.list.archived + 1;
        state.list.open = state.list.open - 1;
      }
    },
    // [addPosition.fulfilled]: (state, action) => {
    //   state.list.data = [...state.list.data, action.payload];
    // },
    [getUsers.fulfilled]: (state, action) => {
      state.users.data = action.payload.rows;
      state.users.count = action.payload.count;
      state.users.totalCount = action.payload.totalCount;
      state.users.totalPages = action.payload.totalPages;
      state.users.countPerPage = action.payload.countPerPage;
      state.users.loading = false;
    },
    [updateCandidateHiringStatus.fulfilled]: (state, action) => {
      state.users.data = state.users.data.map((user) => {
        if (user.userId === action.payload.userId) {
          return { ...user, hiringStatus: action.payload.status };
        }
        return user;
      });
    },
    [getUsers.pending]: (state) => {
      state.users.loading = true;
    },
    [getPositionStats.fulfilled]: (state, action) => {
      state.positionStats.data = action.payload;
      state.positionStats.hasData = !!Object.keys(action.payload).length;
      state.positionStats.loading = false;
    },
    [getPositionStats.pending]: (state) => {
      state.positionStats.loading = true;
    },
    [removeInvitedUser.fulfilled]: (state, action) => {
      state.users.data = state.users.data.filter(
        (user) => user.id !== action?.payload?.userId
      );
    },
  },
});

export const { reset, updateUserStatus, updateUser } = positionSlice.actions;
export default positionSlice.reducer;
