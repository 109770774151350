import { useState, useMemo, useRef, useEffect } from "react"
import { useParams } from "react-router-dom"
import FitScore from "../FitScore"
import ReportHeader from "../Header"
import TraitsGrid from "../TraitsGrid"
import PersonalityComponent from "../PersonalityComponent/PersonalityComponent"
import BucketDetails from "../V2/BucketDetails"
import { FUNCTIONAL_BUCKETS } from "../V2/V2"
import PdfReportApi from "src/services/apis/pdfReport"
import { getFeaturedBuckets, getPersonalityTraits, getTraitsMap } from "src/utils/helperFunctions"
import { BUCKET_NAMES, TEMP_NO_FITSCORE_POSITIONS } from "src/constants/globalConstants"

const CandidateReport = ({ reportData, isNonDnaReport, logMixpanelEvent }) => {
    const [activeBucketId, setActiveBucketId] = useState(null)
    const [activeTrait, setActiveTrait] = useState(null)
    const [positionConfig, setPositionConfig] = useState(null)
    const hoverDelayRef = useRef(null)
    const { linkId } = useParams()

    useEffect(() => {
        fetchPositionConfig()
    }, [])

    const fetchPositionConfig = async () => {
        try {
            const response = await PdfReportApi.getPositionConfig(linkId)
            if (response.success) {
                setPositionConfig(response.positionConfig)
            }
        } catch (err) {
            console.error(err)
        }
    }

    const functionalBuckets = useMemo(() => {
        const buckets = getFeaturedBuckets(reportData?.buckets, FUNCTIONAL_BUCKETS)
        return {
            name: "FUNCTIONAL SKILLS",
            description: "How proficient they are on their domain centric knowledge",
            buckets,
        }
    }, [reportData?.buckets])
    const communicationBuckets = useMemo(() => {
        const buckets = getFeaturedBuckets(reportData?.buckets, [
            BUCKET_NAMES.COMMUNICATION,
        ])
        return {
            name: "COMMUNICATION SKILLS",
            description: "How they understand speech and express themselves",
            buckets,
        }
    }, [reportData?.buckets])
    const competenciesBuckets = useMemo(() => {
        const buckets = getFeaturedBuckets(reportData?.buckets, [
            BUCKET_NAMES.COMPETENCIES,
        ])
        return {
            name: "COMPETENCIES",
            description: "How they control & conduct themselves, solve problems and work with others and react to different situations",
            buckets,
        }
    }, [reportData?.buckets])
    const aptitudeBuckets = useMemo(() => {
        const buckets = getFeaturedBuckets(reportData?.buckets, [
            BUCKET_NAMES.APTITUDE_AND_REASONING,
        ])
        return {
            name: "APTITUDES & REASONING",
            description: "How they interpret information, apply logic to solve problems and draw conclusions",
            buckets,
        }
    }, [reportData?.buckets])
    const personalityBuckets = useMemo(() => {
        const traits = getPersonalityTraits(reportData?.buckets, [
            BUCKET_NAMES.PERSONALITY_DIMENSIONS,
        ])
        return {
            name: BUCKET_NAMES.PERSONALITY_DIMENSIONS,
            traits,
        }
    }, [reportData?.buckets])
    const personalityBucketsIPIPLong = useMemo(() => {
        const traits = getPersonalityTraits(reportData?.buckets, [
            BUCKET_NAMES.IPIP_LONG,
        ])
        return {
            name: BUCKET_NAMES.IPIP_LONG,
            traits,
        }
    }, [reportData?.buckets])
    const personalityBucketsIPIPShort = useMemo(() => {
        const traits = getPersonalityTraits(reportData?.buckets, [
            BUCKET_NAMES.IPIP_SHORT,
        ])
        return {
            name: BUCKET_NAMES.IPIP_SHORT,
            traits,
        }
    }, [reportData?.buckets])
    const checkOnlyPersonalityBucket = useMemo(() => {
        if (reportData?.buckets
            && reportData?.buckets.length === 1
            && (
                reportData?.buckets[0].name === BUCKET_NAMES.PERSONALITY_DIMENSIONS ||
                reportData?.buckets[0].name === BUCKET_NAMES.IPIP_LONG ||
                reportData?.buckets[0].name === BUCKET_NAMES.IPIP_SHORT
            )
        ) return true
        return false
    }, [reportData?.buckets])

    const handleTraitHover = (id) => {
        if (id) {
            const refId = setTimeout(() => setActiveTrait(id), 200)
            hoverDelayRef.current = refId
        } else {
            clearTimeout(hoverDelayRef.current)
            setActiveTrait(null)
        }
    }

    const activeBucketData = activeBucketId
        ? [
            ...functionalBuckets?.buckets,
            ...communicationBuckets?.buckets,
            ...competenciesBuckets?.buckets,
            ...aptitudeBuckets?.buckets
        ].find(
            ({ id }) => id === activeBucketId
        )
        : null

    const hideFitScore = useMemo(() => {
        if (!positionConfig?.positionId) return true

        const { positionId } = positionConfig
        const hiddenFitScore = TEMP_NO_FITSCORE_POSITIONS.includes(positionId)

        return hiddenFitScore || checkOnlyPersonalityBucket
    }, [positionConfig, reportData?.buckets])

    return (
        <div id="one-page-report-component" className="v2">
            <div className="info v2">
                <ReportHeader
                    hideCta
                    reportData={{
                        user: {
                            name: reportData?.user?.name,
                            email: reportData?.user?.email,
                            yearsOfExperience: reportData?.user?.yearsOfExperience,
                            designation: reportData?.user?.designation,
                            dob: reportData?.user?.dob,
                            phone: reportData?.user?.phone,
                            expertiseLevel: reportData?.user?.expertiseLevel
                        },
                        position: {
                            name: reportData?.position?.name
                        }
                    }}
                    isNonDnaReport={isNonDnaReport}
                    logMixpanelEvent={logMixpanelEvent}
                />
                <div className="content-wrapper">
                    <div className="max-width-left">
                        {!activeBucketId ? (
                            <div>
                                {
                                    hideFitScore ?
                                        <></> :
                                        <FitScore
                                            {...reportData?.fitScore}
                                            dnaScore={null}
                                            showLegends={isNonDnaReport}
                                            fitScoreMultiplier={positionConfig?.fitScoreMultiplier ?? 1}
                                        />
                                }
                                {!!functionalBuckets.buckets?.length > 0 &&
                                    functionalBuckets.buckets?.map(
                                        ({ id, name, description, traitsMeta }) =>
                                        (
                                            <div key={id} className="group-container">
                                                <TraitsGrid
                                                    showBucketWise
                                                    hideLegends
                                                    title={name}
                                                    description={description}
                                                    traitsMap={getTraitsMap([{ traitsMeta }])}
                                                    activeTrait={activeTrait}
                                                    onTraitHover={handleTraitHover}
                                                />
                                            </div>
                                        )
                                    )}
                                {!!communicationBuckets.buckets?.length > 0 &&
                                    communicationBuckets.buckets?.map(
                                        ({ id, name, description, traitsMeta }) =>
                                        (
                                            <div key={id} className="group-container">
                                                <TraitsGrid
                                                    showBucketWise
                                                    hideLegends
                                                    title={name}
                                                    description={description}
                                                    traitsMap={getTraitsMap([{ traitsMeta }])}
                                                    activeTrait={activeTrait}
                                                    onTraitHover={handleTraitHover}
                                                />
                                            </div>
                                        )
                                    )}
                                {!!competenciesBuckets.buckets?.length > 0 &&
                                    competenciesBuckets.buckets?.map(
                                        ({ id, name, description, traitsMeta }) =>
                                        (
                                            <div key={id} className="group-container">
                                                <TraitsGrid
                                                    showBucketWise
                                                    hideLegends
                                                    title={name}
                                                    description={description}
                                                    traitsMap={getTraitsMap([{ traitsMeta }])}
                                                    activeTrait={activeTrait}
                                                    onTraitHover={handleTraitHover}
                                                />
                                            </div>
                                        )
                                    )}
                                {!!aptitudeBuckets.buckets?.length > 0 &&
                                    aptitudeBuckets.buckets?.map(
                                        ({ id, name, description, traitsMeta }) =>
                                        (
                                            <div key={id} className="group-container">
                                                <TraitsGrid
                                                    showBucketWise
                                                    hideLegends
                                                    title={name}
                                                    description={description}
                                                    traitsMap={getTraitsMap([{ traitsMeta }])}
                                                    activeTrait={activeTrait}
                                                    onTraitHover={handleTraitHover}
                                                />
                                            </div>
                                        )
                                    )}
                                {!!reportData &&
                                    reportData.buckets?.map(
                                        ({ id, name, description, traitsMeta }) =>
                                            !Object.values(BUCKET_NAMES).includes(name) ? (
                                                <div key={id} className="group-container">
                                                    <TraitsGrid
                                                        showBucketWise
                                                        hideLegends
                                                        title={name}
                                                        description={description}
                                                        traitsMap={getTraitsMap([{ traitsMeta }])}
                                                        activeTrait={activeTrait}
                                                        onTraitHover={handleTraitHover}
                                                    />
                                                </div>
                                            ) : null
                                    )}
                                {!!personalityBuckets.traits?.length > 0 &&
                                    <PersonalityComponent
                                        bucket={personalityBuckets}
                                    />
                                }
                                {!!personalityBucketsIPIPLong.traits?.length > 0 &&
                                    <PersonalityComponent
                                        bucket={personalityBucketsIPIPLong}
                                    />
                                }
                                {!!personalityBucketsIPIPShort.traits?.length > 0 &&
                                    <PersonalityComponent
                                        bucket={personalityBucketsIPIPShort}
                                    />
                                }
                            </div>
                        ) : (
                            activeBucketData && (
                                <BucketDetails
                                    {...activeBucketData}
                                    reportData={reportData}
                                    headerTraitColor={"grey"}
                                    onBack={() => setActiveBucketId(null)}
                                />
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CandidateReport