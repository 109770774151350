import Login from "../pages/Login";
import Positions from "../pages/Positions";
import MassHiring from "../pages/MassHiring";
import PositionForm from "../forms/AddPositionForm";
import PositionDetail from "../pages/Positions/PositionDetail";
import AddPositionForm from "../pages/Positions/AddPosition";
import MyDna from "../pages/MyDna";
import MyDnaDetail from "../pages/MyDna/MyDnaDetail";
import PdfReport from "../pages/PdfReport";
import OnePagerReport from "src/pages/OnePagerReport";
import SelectOrganization from "src/pages/SelectOrganization";
import QuestionsSection from "src/pages/OnePagerReport/QuestionsSection";
import AllSettings from "src/pages/AllSettings";
import { Feedbacks } from "src/pages/Feedbacks";
import PositionAnalytics from "src/pages/Positions/PositionAnalytics";
import PositionDetails from "src/pages/PositionDetails";

export const PUBLIC_ROUTES = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/pdf-report/link/:linkId/users/:userId",
    component: PdfReport,
  },
  {
    path: [
      "/report/link/:linkId/users/:userId",
      "/report/v2/link/:linkId/users/:userId",
      "/report/v2-traits/link/:linkId/users/:userId",
      "/candidate-report/link/:linkId/users/:userId"
    ],
    component: OnePagerReport,
  },
  {
    path: "/position-details/:orgId/:positionId",
    component: PositionDetails
  },
];

export const PROTECTED_ROUTES = [
  {
    path: "/hiring/edit/:positionId",
    component: AddPositionForm,
    showHeader: false,
    showMobileHeader: false,
  },
  {
    path: "/hiring/:positionId",
    component: PositionDetail,
    showHeader: true,
    showMobileHeader: false,
  },
  {
    path: "/hiring/:positionId/analytics",
    component: PositionAnalytics,
    showHeader: true,
    showMobileHeader: false,
  },
  {
    path: "/mass-hiring/:positionId",
    component: PositionDetail,
    showHeader: true,
    showMobileHeader: false,
  },
  {
    path: "/mass-hiring/:positionId",
    component: PositionDetail,
    showHeader: true,
  },
  {
    path: "/myteam",
    component: MyDna,
    showHeader: true,
    showMobileHeader: true,
  },
  {
    path: "/mass-hiring",
    component: MassHiring,
    showHeader: true,
    showMobileHeader: true,
  },
  {
    path: "/mass-hiring",
    component: MassHiring,
    showHeader: true,
  },
  {
    path: "/myteam/detail/:dnaId",
    component: MyDnaDetail,
    showHeader: true,
    showMobileHeader: false,
  },
  {
    path: "/hiring",
    component: Positions,
    showHeader: true,
    showMobileHeader: true,
  },
  {
    path: "/settings",
    component: AllSettings,
    showHeader: true,
    showMobileHeader: true,
  },
  {
    path: "/select-organization",
    component: SelectOrganization,
    showHeader: false,
  },
  {
    path: "/feedbacks",
    component: Feedbacks,
    showHeader: true,
    showMobileHeader: true,
  }
];
