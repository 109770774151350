import styles from "./styles.module.scss";

const SkillsSideMenu = ({ data, className = "", showButton = true }) => {
    const { heading, description, children, btnText, onBtnClick } = data;

    return (
        <article
            className={`${styles["skills-side-menu"]} ${className}`}>
            {
                heading &&
                <p className={styles["heading"]}>
                    {heading}
                </p>
            }
            <p className={!heading ? styles["description-heading"] : styles["description"]}>
                {description}
            </p>
            {children}
            {showButton && btnText && onBtnClick ? (
                <button
                    onClick={onBtnClick}
                    className={styles["button"]}>
                    {btnText}
                </button>
            ) : null}
        </article>
    );
};

export default SkillsSideMenu;