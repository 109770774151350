import styles from "./styles.module.scss";

const ListItems = ({ mapAssessmentSkillsWithBuckets }) => (
    <div className={styles["list-container"]}>
        {Object.entries(mapAssessmentSkillsWithBuckets() ?? {}).map(
            (data, index) => (
                <ul key={data[0]} className={index === 0 ? styles["first-ul"] : styles["ul"]}>
                    <p className={styles["heading"]}>
                        {data[0]?.split("_")?.join(" ") ?? ""}
                    </p>
                    {data[1]?.map((item, idx) => (
                        <li className={styles["list-item"]} key={idx}>
                            • {item.name}
                        </li>
                    ))}
                </ul>
            )
        )}
    </div>
);

export default ListItems;
