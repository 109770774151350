import { Api, UnberryApi } from "../config/request";

const CandidateApi = {
  updateCandidateHiringStatus({ linkId = "", status = {} }) {
    return UnberryApi.safeCall(
      `/api/user/v1/:orgType/:orgId/${linkId}/updateStatus`,
      "PUT",
      {
        data: { status },
      }
    );
  },
  updateCandidateInvite({ userId, orgId, orgType, payload }) {
    return UnberryApi.safeCall(
      `/api/user/v1/${userId}?organizationType=${orgType}&organizationId=${orgId}`,
      "PUT",
      {
        data: { ...payload },
      }
    );
  },
  deleteInvitedCandidate(params) {
    return UnberryApi.safeCall(`/api/company/v1/:orgId/user`, "DELETE", {
      params,
    });
  },
  resendInvite(payload) {
    return UnberryApi.safeCall(`/api/link/v1/resend-invite`, "POST", {
      data: payload,
    });
  },
  cancelInvite(orgId, orgType, payload) {
    return UnberryApi.safeCall(`/api/link/v1/${orgType}/${orgId}/cancel-invitation`, "POST", {
      data: payload,
    });
  },
  getCandidates(params) {
    return UnberryApi.safeCall(`/api/reporting/v1/dna/users`, "GET", {
      params,
    });
  },
  updateCandidateEmail(payload, headers) {
    return Api.post(`/api/integration/v1/update-email`, payload, { ...headers }, false);
  },
  getCandidateFormData(linkId) {
    return Api.get(`/api/session/v1/${linkId}/candidate-form-data`)
  },
  updateDisqualificationStatus(orgType, orgId, payload) {
    return UnberryApi.safeCall(
      `/api/link/v1/remove-disqualify-status?organizationType=${orgType}&organizationId=${orgId}`,
      "PUT",
      {
        data: { ...payload }
      }
    )
  },
};

export default CandidateApi;
