
// Render media component by type
export const GetMediaComponent = ({ vidSrc, audioSrc, imgSrc, audioSrcLabel }) => {
	return (
		<>
			{
				audioSrc &&
				<div className="flex items-center mt-20">
					{
						audioSrcLabel &&
						<span className="semibold mr-20">{audioSrcLabel}</span>
					}
					<audio controls width="100%">
						<source src={audioSrc} type="audio/mpeg" />
					</audio>
				</div>
			}
			{
				imgSrc &&
				<div className="flex items-center my-20">
					<img src={imgSrc} width={"200px"} />
				</div>
			}
			{
				vidSrc &&
				<div className="flex items-center my-20">
					<video controls width="100%" style={{ maxWidth: "400px" }}>
						<source src={vidSrc} />
					</video>
				</div>
			}
		</>
	)
}